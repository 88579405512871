import Pia from '../pia/type';

export const fundFullIntKeys: (keyof Pia.FundsOverviewReloaded)[] = [
  'r_building_expenses',
  'r_capital_distribution',
  'r_units_outstanding',
  'r_estimated_liquidation_taxes',
  'r_maintenance_and_repairs_expenses',
  'r_profit_and_capital_taxes',
  'r_property_tax',
  'r_realised_capital_gains_and_losses',
  'r_realised_income',
  'r_rental_income',
  'r_retained_earnings',
  'r_revenue_from_postal_and_bank_accounts',
  'r_short_term_interest_bearing_mortgages',
  'r_short_term_liabilities',
  'r_taxes',
  'r_total_cash',
  'r_total_dividend',
  'r_total_expenses',
  'r_total_income',
  'r_total_interest',
  'r_total_liabilities',
  'r_total_properties',
  'r_total_revenue',
  'r_unrealised_capital_gains_and_losses',
  'r_valuation_and_auditing_expenses',
  'r_valuation_expenses',

  'r_market_maker_fees',
  'r_absolute_custodian_bank_fees',
  'r_absolute_management_fees',
  'r_administration_of_buildings',
  'r_auditing_expenses',

  'c_current_liabilities',

  'c_operating_profit',

  'r_dividend_distribution',

  'r_ebit',
  'r_invested_capital',
  'r_long_term_interest_bearing_mortgages',
  'r_long_term_liabilities',

  'r_mortgage_interest',
  'r_negative_interest',
  'r_net_assets_at_beginning_of_the_financial_year',
  'r_net_assets_before_estimated_liquidation_taxes',
  'r_net_income',
  'r_net_income_available_for_distribution',
  'r_nopat',
  'r_ordinary_income_carried_forward_from_previous_financial_year',
  'r_other_assets',
  'r_other_expenses',
  'r_other_interest',
  'r_other_long_term_liabilities',
  'r_other_revenue',
  'r_other_short_term_liabilities',

  'r_market_cap',
  'r_tga_after_distribution',
  'r_tga_before_distribution',
  'r_tna_after_distribution',
  'r_tna_before_distribution',
  'c_total_agio_at_year_end',

  'c_tga_compounded',
  'c_tna_compounded',
  'c_total_current_agio_after_distribution',
  'c_total_current_agio_compounded',

  'm_market_cap',

  'r_total_area',
  'r_commercial_spaces_area',
  'r_residential_area',
  'total_area',
  'commercial_spaces_area',
  'residential_area',

  'r_capital_employed',
  'net_rent',
  'potential_total_income',

  'building_groups_nb',
  'buildings_nb',
  'dwellings_nb',
  'lots_nb',

  'r_unrealised_gains_and_losses_including_var_est_liq_tax',
  'five_days_avg_volume',
  'one_month_avg_volume',
  'one_year_avg_volume',
  'six_months_avg_volume',
  'three_months_avg_volume',
  'volume',
];

export default fundFullIntKeys;
