import { createApi } from '@reduxjs/toolkit/query/react';
import {
  LoginFlow,
  UpdateLoginFlowWithPasswordMethod,
  Session,
  SuccessfulNativeLogin,
  SettingsFlow,
  UpdateSettingsFlowWithPasswordMethod,
  UpdateSettingsFlowWithProfileMethod,
  UpdateVerificationFlowWithCodeMethod,
  VerifiableIdentityAddress,
  VerificationFlow,
  UpdateLoginFlowWithTotpMethod,
  RecoveryFlow,
  UpdateSettingsFlowWithLookupMethod,
  UpdateLoginFlowWithLookupSecretMethod,
} from '@ory/kratos-client';
import fetchCustomQuery from '../helpers/reAuth';
import API from '../api/types';
import { Language } from './types';
import { TokenType } from '@/pages/Settings/Connections/types';

const { VITE_API_URI } = import.meta.env;

const kratosApi = createApi({
  reducerPath: 'kratosApi',
  baseQuery: fetchCustomQuery({
    baseUrl: VITE_API_URI,
    credentials: 'include',
  }),
  tagTypes: [
    'ProfilePicture',
    'Language',
    'EmailSettings',
    'TablePreferences',
    'ViewPreferences',
    'Tokens',
    'ValuationPreferences',
    'PresetPreferences',
    'NotificationPreferences',
    'GraphPreferences',
    'VariationPreferences',
  ],
  endpoints: (build) => ({
    getLogin: build.query<LoginFlow, boolean | undefined | void>({
      query: (aal2) => ({
        url: 'user/flows/login',
        headers: {
          accept: 'application/json',
        },
        params: aal2 ? { aal2 } : {},
      }),
      extraOptions: { bypassMutex: true },
    }),
    postLogin: build.mutation<
      SuccessfulNativeLogin,
      (
        | UpdateLoginFlowWithPasswordMethod
        | UpdateLoginFlowWithTotpMethod
        | UpdateLoginFlowWithLookupSecretMethod
      ) & { flow: string }
    >({
      query: ({ flow, ...form }) => {
        const { totp_code, ...passwordMethod } = form as UpdateLoginFlowWithTotpMethod;
        const { identifier, password, ...totpMethod } = form as UpdateLoginFlowWithPasswordMethod;
        return {
          url: 'user/login',
          method: 'POST',
          body: totp_code ? totpMethod : !totp_code && !identifier ? form : passwordMethod,
          params: { flow },
        };
      },
      extraOptions: { bypassMutex: true },
    }),
    logout: build.query<void, void>({
      query: () => ({
        url: 'user/logout',
      }),
      extraOptions: { bypassMutex: true },
    }),
    getRecovery: build.query<RecoveryFlow, string>({
      query: (id?: string) => ({
        url: 'user/flows/recovery',
        headers: {
          accept: 'application/json',
        },
        params: id ? { flow: id } : {},
      }),
    }),
    postRecovery: build.mutation({
      query: ({ flow, ...form }) => {
        const { code, email, ...rest } = form;
        const method = 'code';
        return {
          url: 'user/recovery',
          method: 'POST',
          body: code ? { ...rest, code, method } : { ...rest, email, method },
          params: { flow },
        };
      },
    }),
    getVerification: build.query<VerificationFlow, { id?: string; code?: string }>({
      query: ({ id, code }) => ({
        url: 'user/flows/verification',
        headers: {
          accept: 'application/json',
        },
        params: id ? (code ? { flow: id, code } : { flow: id }) : {},
      }),
    }),
    postVerification: build.mutation<
      VerifiableIdentityAddress | VerificationFlow,
      UpdateVerificationFlowWithCodeMethod & { flow?: string }
    >({
      query: ({ flow, ...form }) => {
        return {
          url: `user/verification`,
          method: 'POST',
          body: { ...form, method: 'code' },
          params: flow ? { flow } : {},
        };
      },
    }),
    getSettings: build.query<SettingsFlow, void>({
      query: () => ({
        url: 'user/flows/settings',
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    postSettings: build.mutation<
      void,
      (
        | UpdateSettingsFlowWithPasswordMethod
        | UpdateSettingsFlowWithProfileMethod
        | UpdateLoginFlowWithTotpMethod
        | UpdateSettingsFlowWithLookupMethod
      ) & {
        flow: string;
      }
    >({
      query: ({ flow, ...form }) => {
        return {
          url: `user/settings`,
          method: 'POST',
          body: { ...form },
          params: { flow },
        };
      },
    }),
    getSession: build.query<{ session: Session }, void>({
      query: () => ({
        url: 'user/whoami',
        headers: {
          accept: 'application/json',
        },
      }),
      extraOptions: { bypassMutex: true },
    }),

    putCodeSeen: build.mutation<void, void>({
      query: () => ({
        url: 'user/flows/seen',
        method: 'PUT',
      }),
    }),

    deleteTrustedDevice: build.mutation<void, void>({
      query: () => ({
        url: `user/flows/two-factor-recovery`,
        method: 'DELETE',
      }),
    }),

    deletePP: build.mutation<void, void>({
      query: () => ({ url: 'user/configuration/profile-picture', method: 'DELETE' }),
      invalidatesTags: ['ProfilePicture'],
    }),

    updatePP: build.mutation({
      query: (photo) => ({
        url: 'user/configuration/profile-picture',
        body: photo,
        method: 'PUT',
        formData: true,
      }),
      invalidatesTags: ['ProfilePicture'],
    }),
    updateEmailSettings: build.mutation({
      query: (body) => ({
        url: 'user/configuration/emails-settings',
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['EmailSettings'],
    }),
    getLang: build.query<{ language: Language }, void>({
      query: () => ({
        url: 'user/configuration/language',
      }),
      providesTags: ['Language'],
    }),
    getEmailSettings: build.query<
      { email_settings: { marketing: boolean; technical: boolean } | null },
      void
    >({
      query: () => ({
        url: 'user/configuration/emails-settings',
      }),
      providesTags: ['EmailSettings'],
    }),
    getMe: build.query<{ user: API.User }, void>({
      query: () => ({
        url: 'user',
      }),
      providesTags: ['ProfilePicture'],
    }),

    getUserTokens: build.query<{ tokens: Array<TokenType> }, void>({
      query: () => ({
        url: 'user/configuration/token',
        method: 'GET',
      }),
      providesTags: ['Tokens'],
    }),
    getAppTokens: build.query<
      { tokens: Array<TokenType>; count: number; totalCount: number },
      { page: number; text: string }
    >({
      query: ({ page, text }: { page: number; text: string }) => ({
        url: `users/tokens`,
        method: 'GET',
        params: { page, text },
      }),

      providesTags: ['Tokens'],
    }),
    createToken: build.mutation<
      { token: string },
      { name: string; expirationTime: number; expirationType: 'minutes' | 'hours' | 'days' }
    >({
      query: ({ name, expirationTime, expirationType }) => ({
        url: 'user/configuration/token',
        method: 'POST',
        body: {
          name,
          expirationTime,
          expirationType,
        },
      }),
      invalidatesTags: ['Tokens'],
    }),
    deleteToken: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `user/configuration/token/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tokens'],
    }),

    updateLang: build.mutation<void, { language: string }>({
      query: (body) => ({
        url: 'user/configuration/language',
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Language'],
    }),

    checkRecoveryCodes: build.query<{ is_code_expired: boolean; is_code_existent: boolean }, void>({
      query: () => ({
        url: 'user/configuration/recovery-code',
      }),
    }),
    getValuationPreferences: build.query<API.ValuationPreferences, void>({
      query: () => ({
        url: 'user/configuration/valuation-preferences',
      }),
      transformResponse: (data: { valuation_preferences: API.ValuationPreferences }) => {
        return data.valuation_preferences;
      },
      providesTags: ['ValuationPreferences'],
    }),
    getTablePreferences: build.query<API.TablePreferences, void>({
      query: () => ({
        url: 'user/configuration/table-preferences',
      }),
      transformResponse: (data: { table_preferences: API.TablePreferences }) => {
        return data.table_preferences;
      },
      providesTags: ['TablePreferences'],
    }),
    getNotificationPreferences: build.query<API.NotificationPreferences, void>({
      query: () => ({
        url: 'user/configuration/notification-preferences',
      }),
      transformResponse: (data: { notification_preferences: API.NotificationPreferences }) => {
        return data.notification_preferences;
      },
      providesTags: ['NotificationPreferences'],
    }),
    getViewPreferences: build.query<API.ViewPreferences, void>({
      query: () => ({
        url: 'user/configuration/view-preferences',
      }),
      transformResponse: (data: { view_preferences: API.ViewPreferences }) => {
        return data.view_preferences;
      },
      providesTags: ['ViewPreferences'],
    }),
    getPresetPreferences: build.query<API.PresetPreferences, void>({
      query: () => ({
        url: 'user/configuration/preset-preferences',
      }),
      transformResponse: (data: { preset_preferences: API.PresetPreferences }) => {
        return data.preset_preferences;
      },
      providesTags: ['PresetPreferences'],
    }),
    getGraphPreferences: build.query<API.GraphPreferences, void>({
      query: () => ({
        url: 'user/configuration/graph-preferences',
      }),
      transformResponse: (data: { graph_preferences: API.GraphPreferences }) => {
        return data.graph_preferences;
      },
      providesTags: ['GraphPreferences'],
    }),
    updateGraphPreferences: build.mutation<void, { graph: string; preference: Array<string> }>({
      query: ({ graph, preference }) => ({
        url: 'user/configuration/graph-preferences',
        body: { graph, preference },
        method: 'PATCH',
      }),
      invalidatesTags: ['GraphPreferences'],
    }),

    getVariationPreferences: build.query<API.VariationPreferences, void>({
      query: () => ({
        url: 'user/configuration/variation-preferences',
      }),
      transformResponse: (data: { variation_preferences: API.VariationPreferences }) => {
        return data.variation_preferences;
      },
      providesTags: ['VariationPreferences'],
    }),
    updateVariationPreferences: build.mutation<void, { tile: string; deltaT: number }>({
      query: ({ tile, deltaT }) => ({
        url: 'user/configuration/variation-preferences',
        body: { tile, deltaT },
        method: 'PATCH',
      }),
      invalidatesTags: ['VariationPreferences'],
    }),
    updateTablePreferences: build.mutation<void, { table: string } & Partial<API.TablePreference>>({
      query: ({ columns_to_ignore, ...rest }) => ({
        url: 'user/configuration/table-preferences',
        body: { columnsToIgnore: columns_to_ignore, ...rest },
        method: 'PATCH',
      }),
    }),

    updateValuationPreferences: build.mutation<
      undefined,
      { type: 'dwellings' | 'buildings'; preferences: API.ValuationPreference }
    >({
      query: ({ type, preferences }) => ({
        url: `user/configuration/valuation-preferences?type=${type}`,
        body: { ...preferences },
        method: 'PATCH',
      }),
    }),
    updateViewPreferences: build.mutation<void, { table: string; type?: API.ViewType }>({
      query: ({ table, type }) => ({
        url: 'user/configuration/view-preferences',
        body: { table, type },
        method: 'PATCH',
      }),
    }),
    updateNotificationPreferences: build.mutation<
      void,
      { updatedPart: Partial<API.NotificationPreferences> }
    >({
      query: ({ updatedPart }) => ({
        url: 'user/configuration/notification-preferences',
        body: updatedPart,
        method: 'PATCH',
      }),
      invalidatesTags: ['NotificationPreferences'],
    }),
    updatePresetPreferences: build.mutation<void, { type: 'fund' | 'asset'; id: string }>({
      query: ({ type, id }) => ({
        url: 'user/configuration/preset-preferences',
        body: { type, id },
        method: 'PATCH',
      }),
    }),
    deletePresetPreferences: build.mutation<void, { type: 'fund' | 'asset' }>({
      query: ({ type }) => ({
        url: `user/configuration/preset-preferences/${type}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export default kratosApi;
