import { useAppSelector } from '@/app/hooks';
import { selectPathHistory } from '@/features/api/navigationSlice';
import UseTitle from '@/features/helpers/UseTitle';
import '@/scss/pages/ErrorPage.css';
import { Button } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export default function NotFoundPage({
  isError,
  noBackground,
}: {
  isError?: boolean;
  noBackground?: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = useAppSelector(selectPathHistory);
  const currLocation = useLocation();
  const updateTitle = UseTitle();

  const handleBack = () => {
    if (pathname.length === 1 && pathname[pathname.length - 1] === currLocation.pathname) {
      if (isError) location.assign('/');
      else navigate('/');
    } else {
      if (isError) location.assign(pathname[pathname.length - 2]);
      else navigate(-1);
    }
  };

  useEffect(() => {
    updateTitle({
      element: t(isError ? 'error.something_went_wrong.title' : 'error.notfound.title'),
    });
  }, [updateTitle, t, isError]);

  return (
    <div className={`page-error ${noBackground ? 'noBackground' : ''}`}>
      <div className="page-error-text">
        {isError !== true && <span>{t('error.notfound.code')}</span>}
        <h1>{t(isError ? 'error.something_went_wrong.title' : 'error.notfound.title')}</h1>
        <Button onClick={handleBack} variant="light">
          {t('error.notfound.button')}
        </Button>
      </div>
    </div>
  );
}
