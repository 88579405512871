// Why a hand-made deepmerge?
// As far as I've searched, other deepMerge in the market does Mutation
// And Mutation is against my religion...
export default function deepMerge(
  destination: Record<string, any> = {},
  source: Record<string, any>,
  removeNil?: boolean
): Record<string, any> {
  if (!source) return destination;
  const sourceKeys = Object.keys(source);
  const keys = [...new Set([...sourceKeys, ...Object.keys(destination || {})])];
  return keys.reduce((final: Record<string, any>, key: string) => {
    const element = source[key];
    if (typeof element === 'object' && !Array.isArray(element) && element !== null) {
      if (element instanceof Date) {
        return {
          ...final,
          [key]: element,
        };
      }
      return {
        ...final,
        [key]: deepMerge(destination?.[key] || {}, element, removeNil),
      };
    }
    // if (Array.isArray(element) && !element.length && destination?.[key]?.length) {
    //   return { ...final, [key]: destination?.[key] };
    // }
    if (!sourceKeys.includes(key)) {
      return { ...final, [key]: destination?.[key] };
    }
    if (removeNil && element === null) {
      return final;
    }
    if (!element && element !== false && element !== null) {
      return { ...final, [key]: destination?.[key] };
    }
    return { ...final, [key]: element };
  }, {});
}
