import { ChangeEventHandler, useEffect, useRef } from 'react';
import '@/scss/components/tableCells/TableCheckbox.css';

interface PCheckBox {
  isChecked: boolean;
  isIndeterminate?: boolean;
  onClick: ChangeEventHandler<HTMLInputElement>;
  isHeader?: boolean;
  isDisabled?: boolean;
}

export default function TableCheckbox({
  isChecked,
  isIndeterminate = false,
  onClick,
  isHeader,
  isDisabled,
}: PCheckBox) {
  const cRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = cRef.current;
    if (input) input.indeterminate = isIndeterminate;
  }, [cRef, isIndeterminate]);

  return (
    <div className="table-checkbox-container">
      <input
        className={`table-checkbox clickable${isHeader ? ' header' : ''}`}
        type="checkbox"
        checked={isChecked}
        ref={cRef}
        onChange={onClick}
        disabled={isDisabled}
      />
    </div>
  );
}
