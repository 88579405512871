import Pia from '../pia/type';

export const fundFullFloatKeys: (keyof Pia.FundsOverviewReloaded)[] = [
  'total_co2',
  'energy_intensity',
  'c_pe_ratio', // Is mark as % in excel but is float
  'c_gav_after_distribution',
  'c_nav_after_distribution',
  'c_gav_compounded',
  'c_nav_compounded',
  'alpha',
  'beta',
  'm_share_price',
  'fifty_two_weeks_high',
  'fifty_two_weeks_low',
  'high',
  'last',
  'low',
  'open',
  'r_share_price',
  'turnover',

  'r_dividend_from_capital_gains',
  'r_dividend_from_direct_rental_income',
  'r_dividend_from_indirect_rental_income',
  'r_gav_before_distribution',
  'r_nav_before_distribution',

  'total_co2',

  'c_total_cash_per_share',
  'c_earnings_per_share',
  'c_revenue_per_share',
  'm_dividend',
  'r2',
  'three_years_return',
  'c_median_maturity',
  'c_weighted_average_maturity',
  'r_wault',
];

export default fundFullFloatKeys;
