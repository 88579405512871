import biFilter from '@/features/helpers/bifilter';
import { visibilityStateToColsToIgnore } from '@/features/helpers/visibilityConverter';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
  ColumnOrderState,
  Row,
  Table,
  TableState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CRow,
  CTableCustomOptions,
  CTableOptions,
  PTable4,
  TGetSubRows,
  TTableData,
  isDataSubRow,
  isOptionsExpandable,
} from '../helpers/types';

export const defaultInitialState: TableState = {
  columnSizing: {},
  columnSizingInfo: {
    startOffset: null,
    startSize: null,
    deltaOffset: null,
    deltaPercentage: null,
    isResizingColumn: false,
    columnSizingStart: [],
  },
  rowSelection: {},
  expanded: {},
  grouping: [],
  sorting: [],
  columnFilters: [],
  columnPinning: {
    left: [],
    right: [],
  },
  globalFilter: {},
  rowPinning: {
    top: [],
    bottom: [],
  },
  columnOrder: [],
  columnVisibility: {},
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

// Recursive function to have all subrows:
function getAllSubIds<T>(row: CRow<T>): Array<string> {
  return [row.id, ...(row.subRows || []).map(getAllSubIds).flat()].flat();
}

// Recursive function to have all expanded subrows:
function getAllExpandedSubIds<T>(row: CRow<T>): Array<string> {
  if (!row.getIsExpanded()) return [row.id];
  return [row.id, ...(row.subRows || []).map(getAllExpandedSubIds).flat()];
}

export default function useTableCore<T>({
  data,
  columns,
  options,
  setState,
  initialState,
  id,
}: PTable4<T>): [
  Table<TTableData<T>>,
  {
    state: TableState;
    customOptions: CTableCustomOptions;
    handleDragEnd: (event: DragEndEvent) => void;
    id?: string;
  },
] {
  const [state, setSelfState] = useState<TableState>({ ...defaultInitialState, ...initialState });
  const isInitiated = useRef(false);

  const getRowCanExpand = useCallback(
    (row: Row<TTableData<T>>) => {
      if (options?.enableExpanding || options?.enableExpanding === undefined)
        if (isOptionsExpandable<T>(options)) return options.getRowCanExpand(row);
        else return isDataSubRow(row.original);
      else return false;
    },
    [options]
  );

  const getSubRows: TGetSubRows<T> = useCallback(
    (row, index) => {
      if (isOptionsExpandable<T>(options)) {
        return options.getSubRows(row, index);
      } else if (isDataSubRow(row)) return row.subRows as TTableData<T>[];

      return [];
    },
    [options]
  );

  const [tableOptions, customOptions] = useMemo<[CTableOptions<T>, CTableCustomOptions]>(() => {
    if (!options) return [{}, {}];
    if (isOptionsExpandable(options)) {
      // We remove `getRowCanExpand` & `getSubRows` because we create it with default value above.
      const { getRowCanExpand, getSubRows, ..._options } = options;

      const { enableStickyRowPinning, ...restTableOptions } = _options;
      return [restTableOptions, { enableStickyRowPinning }];
    }

    const { enableStickyRowPinning, ...tableOptions } = options;
    return [tableOptions, { enableStickyRowPinning }];
  }, [options]);

  const table = useReactTable({
    columns,
    data,
    state,
    onPaginationChange(updaterOrValue) {
      setSelfState(({ pagination: prevPagination, ...prev }) => {
        const pagination =
          typeof updaterOrValue === 'function' ? updaterOrValue(prevPagination) : updaterOrValue;
        return { ...prev, pagination };
      });
    },
    onStateChange: setSelfState,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // It was not logic that adding pin to the right would act like the left-add
    // Newly added element to the right will become the latest element of the table
    // IMO, newly added should stack left of the last added element
    // Before changes: firstPin -> firstPin, secondPin -> firstPin, secondPin, thirdPin
    // After changes: firstPin -> secondPin, firstPin -> thirdPin, secondPin, firstPin
    onColumnPinningChange(updaterOrValue) {
      setSelfState(({ columnPinning: prevColumnPinning, ...prev }) => {
        const columnPinning =
          typeof updaterOrValue === 'function' ? updaterOrValue(prevColumnPinning) : updaterOrValue;

        const [newRightColumn, oldRightColumn] = biFilter(
          columnPinning.right || [],
          (col) => !prevColumnPinning.right?.includes(col)
        );
        const leftColumn = columnPinning.left;

        return {
          columnPinning: {
            left: leftColumn,
            right: [...newRightColumn.reverse(), ...oldRightColumn],
          },
          ...prev,
        };
      });
    },
    // Same goes for bottom row pinning
    onRowPinningChange(updaterOrValue) {
      setSelfState(({ rowPinning: prevRowPinning, expanded: prevExpanded, ...prev }) => {
        const rowPinning =
          typeof updaterOrValue === 'function' ? updaterOrValue(prevRowPinning) : updaterOrValue;

        const [newBottomRow, oldBottomRow] = biFilter(
          rowPinning.bottom || [],
          (col) => !prevRowPinning.bottom?.includes(col)
        );
        const topRow = rowPinning.top || [];
        // End of new logic

        // Now...
        // We want to collapse on pin, so Row pinning always work properly:
        const { bottom: prevBottom = [], top: prevTop = [] } = prevRowPinning;
        const allBottomKeys = [...new Set([...prevBottom, ...newBottomRow])];
        const allTopKeys = [...new Set([...prevTop, ...topRow])];
        const allKeys = [...new Set([...allBottomKeys, ...allTopKeys])];

        // Bifiltering by who changed pin:
        const [pinChanged] = biFilter(allKeys, (key) => {
          const wasBottom = prevBottom.includes(key);
          const wasTop = prevTop.includes(key);
          const isBottom = newBottomRow.includes(key);
          const isTop = topRow.includes(key);

          const isAddedTop = !wasTop && isTop;
          const isAddedBottom = !wasBottom && isBottom;
          const isDeleted = (wasBottom || wasTop) && !isTop && !isBottom;

          return isAddedTop || isAddedBottom || isDeleted;
        });

        // Those to collapse:
        const toCollapse = pinChanged
          .map((id) => {
            const row = table.getRow(id);
            const ids = getAllExpandedSubIds(row).flat();
            return ids;
          })
          .flat();

        // Those to unpin:
        const toUnpin = pinChanged
          .map((id) => {
            const row = table.getRow(id);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_rowId, ...ids] = getAllSubIds(row).flat();
            return ids;
          })
          .flat();

        // Now we reset Expansion:
        const newExpanded = Object.keys(prevExpanded).reduce((acc, key) => {
          if (typeof acc === 'boolean') return acc;
          const tmp = key as keyof typeof acc;
          const { [tmp]: value, ...rest } = acc;
          if (value && toCollapse.includes(key)) return rest;
          return acc;
        }, prevExpanded);

        return {
          expanded: newExpanded,
          rowPinning: {
            top: topRow.filter((id) => !toUnpin.includes(id)),
            bottom: [...newBottomRow.reverse(), ...oldBottomRow].filter(
              (id) => !toUnpin.includes(id)
            ),
          },
          ...prev,
        };
      });
    },

    // When expanding, we want to pin children the correct way:
    onExpandedChange(updaterOrValue) {
      setSelfState(({ expanded: prevExpanded, rowPinning: prevRowPinning, ...prev }) => {
        const expanded =
          typeof updaterOrValue === 'function' ? updaterOrValue(prevExpanded) : updaterOrValue;

        const prevKeys = Object.keys(prevExpanded);
        const newKeys = Object.keys(expanded);
        const allKeys = [...new Set([...prevKeys, ...newKeys])];

        // Bifiltering by who is collapsed or expanded:
        const [collapseds, expandeds] = biFilter(allKeys, (key) => {
          const was = prevKeys.includes(key);
          const is = newKeys.includes(key);
          const isAdded = !was && is;
          const isDeleted = was && !is;
          if (isAdded) return false;
          if (isDeleted) return true;
          return undefined;
        });

        const [bottomToPin, topToPin] = expandeds.reduce(
          (acc, id) => {
            const row = table.getRow(id);
            const isPinned = row.getIsPinned();
            if (isPinned) {
              const ids = row.subRows.map(getAllExpandedSubIds).flat();
              if (isPinned === 'bottom') return [{ ...acc[0], [row.id]: ids }, acc[1]];
              return [acc[0], { ...acc[1], [row.id]: ids }];
            }
            return acc;
          },
          [{}, {}]
        );

        const toUnpin = collapseds
          .map((id) => {
            const row = table.getRow(id);
            const isPinned = row.getIsPinned();
            if (isPinned) {
              const ids = row.subRows.map(getAllSubIds).flat();
              return ids;
            }
            return [];
          })
          .flat();

        const { bottom, top } = prevRowPinning;
        const topKeys = Object.keys(topToPin);
        const bottomKeys = Object.keys(bottomToPin);
        const newTop = topKeys.reduce(
          (acc, id) => {
            if (!acc.length) return acc;
            const ids: string[] = topToPin[id as keyof typeof topToPin] || [];

            const index = acc.findIndex((toCompare) => toCompare === id);
            acc.splice(index + 1, 0, ...ids);
            return acc;
          },
          top?.filter((id) => !toUnpin.includes(id) && !bottomKeys.includes(id)) || []
        );
        const newBottom = bottomKeys.reduce(
          (acc, id) => {
            if (!acc.length) return acc;
            const ids: string[] = bottomToPin[id as keyof typeof bottomToPin] || [];

            const index = acc.findIndex((toCompare) => toCompare === id);
            if (index === -1) return acc;
            acc.splice(index + 1, 0, ...ids);
            return acc;
          },
          bottom?.filter((id) => !toUnpin.includes(id) && !topKeys.includes(id)) || []
        );

        return {
          expanded,
          rowPinning: { bottom: newBottom, top: newTop },
          ...prev,
        };
      });
    },

    ...tableOptions,
    getRowCanExpand,
    getSubRows,
  });

  const [columnIds, setColumnIds] = useState<Array<string>>(
    columns.map(({ id }, index) => id || `${index}`)
  );

  useEffect(() => {
    setColumnIds(columns.map(({ id }, index) => id || `${index}`));
  }, [columns]);

  useEffect(() => {
    setSelfState((prev) => {
      const { columnOrder, ...rest } = prev;
      const pinnedColumns = [
        ...(state.columnPinning.left || []),
        ...(state.columnPinning.right || []),
      ];
      const hiddenColumns = visibilityStateToColsToIgnore(state.columnVisibility);

      const toRemove = [...pinnedColumns, ...hiddenColumns];
      const columnOrderFiltered = columnOrder.filter((column) => !toRemove.includes(column));
      const allColumnsFiltered = columnIds.filter(
        (column) => !toRemove.includes(column) && !columnOrderFiltered.includes(column)
      );
      const newColumnOrder = [...columnOrderFiltered, ...allColumnsFiltered];
      return { ...rest, columnOrder: newColumnOrder };
    });
  }, [state.columnPinning, state.columnVisibility, columnIds]);

  useEffect(() => {
    // Infinite loop on empty array....
    if (data?.length) setState?.(state);
    else if (!isInitiated.current) {
      setState?.(state);
      isInitiated.current = true;
    }
  }, [data?.length, setState, state]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        setSelfState(({ columnOrder, ...rest }) => {
          const oldIndex = state.columnOrder.indexOf(active.id as string);
          const newIndex = state.columnOrder.indexOf(over.id as string);
          return {
            ...rest,
            columnOrder: arrayMove<ColumnOrderState[number]>(state.columnOrder, oldIndex, newIndex),
          };
        });
      }
    },
    [setSelfState, state.columnOrder]
  );

  return [table, { state, customOptions, handleDragEnd, id }];
}
