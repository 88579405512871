import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from '@/features/kratos';
import deepMerge from '@/features/helpers/deepMerge';

interface InitialState {
  table: API.TablePreferences;
}

const initialState: InitialState = { table: {} };

export const tablePreferencesSlice = createSlice({
  name: 'table_preferences',
  initialState,
  reducers: {
    updateTablePreference(state, action: PayloadAction<API.TablePreferences>) {
      state.table = deepMerge(state.table, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(kratosApi.endpoints.getTablePreferences.matchFulfilled, (state, action) => {
        state.table = action.payload;
      })
      .addMatcher(kratosApi.endpoints.updateTablePreferences.matchPending, (state, action) => {
        const payload = {
          [action.meta.arg.originalArgs.table]: {
            columns_to_ignore: action.meta.arg.originalArgs.columns_to_ignore,
            sort: action.meta.arg.originalArgs.sort?.length
              ? action.meta.arg.originalArgs.sort
              : null,
            row_per_page: action.meta.arg.originalArgs.row_per_page,
            order: action.meta.arg.originalArgs.order,
            column_pinning: action.meta.arg.originalArgs.column_pinning,
          },
        };
        const newTable = deepMerge(state.table, payload, true);
        state.table = newTable;
      });
  },
});

export const selectTablePreference = (state: RootState) => state.table_preferences.table;

export const tablePreferencesReducer = tablePreferencesSlice.reducer;
export const { updateTablePreference } = tablePreferencesSlice.actions;
