import { OverviewActionFunction } from '../ObjectList/type';
import { ReactNode } from 'react';
import { TableActionFunction } from '../Table/types';
import { TableActionProps } from './type';

type Asset = TableActionProps['asset'] | 'fund';

export type TableActionPropsSpecial = Omit<TableActionProps, 'asset'> & { asset: Asset };

export type OverviewProps = TableActionPropsSpecial & {
  assetId: number;
};

export interface Return {
  enabled: boolean;
  label: string;
  icon: ReactNode;
}

export type OverviewReturn = Return & {
  function?: OverviewActionFunction;
};

export type TableReturn = Return & {
  function?: TableActionFunction;
};

export function isOverview(props: unknown): props is OverviewProps {
  return Boolean((props as OverviewProps).assetId);
}
