// src/frontend/fr.json
var table_header = {
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "Gains/pertes non r\xE9alis\xE9s incluant la variation de la taxe de liquidation",
  region: "R\xE9gion",
  r_net_assets_before_estimated_liquidation_taxes: "Actifs nets avant imp\xF4ts en cas de liquidation estim\xE9s",
  r_estimated_liquidation_taxes: "Taxes de liquidation estim\xE9es",
  estimated_area: "Zone",
  environment: {},
  estimated_co2_emission_per_m2: "CO\u2082 par m\xB2",
  estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2"
};
var boarding_page = {
  totp_setup: {
    notification: {
      privileged_session_reached: {}
    }
  },
  register: {
    notification: {
      privileged_session_reached: {},
      changed_email: {
        message: "Vos informations ont \xE9t\xE9 mises \xE0 jour avec succ\xE8s - Vous devriez avoir re\xE7u un nouvel e-mail pour v\xE9rifier votre nouvelle adresse e-mail.",
        title: "Adresse e-mail chang\xE9e"
      },
      flow_expiration: {
        message: "Le flux a expir\xE9 - Contactez votre administrateur.",
        title: "Expir\xE9"
      }
    },
    error: {
      repeat_password: "Les mots de passe doivent \xEAtre identiques"
    },
    button: "Enregistrer",
    subtitle: "Configurez votre compte :",
    title: "Bienvenue chez Quanthome_"
  },
  login: {
    missing_otp: {
      title: "Configuration OTP invalide"
    },
    aal2: {
      submit_button: "Soumettre",
      forgot_button: "Utiliser des codes de r\xE9cup\xE9ration",
      subtitle: "Veuillez compl\xE9ter le deuxi\xE8me d\xE9fi d'authentification.",
      title: "Entrez le OTP (mot de passe \xE0 usage unique)"
    },
    aal1: {
      submit_button: "Connexion",
      forgot_button: "Mot de passe oubli\xE9/r\xE9initialiser ?",
      subtitle: "Bienvenue de nouveau !",
      title: "Connectez-vous \xE0 votre compte"
    },
    failed: {
      title: "Erreur lors de la connexion"
    },
    abort: "Annuler"
  },
  recovery_code: {
    missing: {
      title: "Code de r\xE9cup\xE9ration invalide"
    },
    abort_button: "Annuler",
    faq: {
      lock: {
        answer: "Veuillez contacter votre administrateur pour la r\xE9cup\xE9ration.",
        question: "Bloqu\xE9 dehors ?"
      },
      what_is_recovery: {
        answer: "Le code de r\xE9cup\xE9ration est le code que vous avez re\xE7u lorsque vous avez li\xE9 votre appareil pour la premi\xE8re fois. Vous devriez l'avoir gard\xE9 quelque part en s\xE9curit\xE9.",
        question: "Qu'est-ce qu'un code de r\xE9cup\xE9ration ?"
      },
      description: "Si vous ne parvenez pas \xE0 acc\xE9der \xE0 votre appareil mobile, entrez votre code de r\xE9cup\xE9ration pour v\xE9rifier votre identit\xE9."
    },
    form: {
      button: "V\xE9rifier"
    },
    title: "R\xE9cup\xE9ration \xE0 deux facteurs",
    reset: {
      success: {
        message: "Vous pouvez maintenant lier un nouvel appareil.",
        title: "Le code de r\xE9cup\xE9ration est correct"
      }
    }
  },
  show_recovery: {
    explain: {
      regenerate_code: "Lorsque vous g\xE9n\xE9rez de nouveaux codes de r\xE9cup\xE9ration, vous devez t\xE9l\xE9charger ou imprimer les nouveaux codes. Vos anciens codes ne fonctionneront plus."
    },
    label: {
      regenerate_code: "G\xE9n\xE9rer de nouveaux codes de r\xE9cup\xE9ration"
    },
    button: {
      regenerate_code: "G\xE9n\xE9rer de nouveaux codes de r\xE9cup\xE9ration",
      proceed: "Proc\xE9der",
      print: "Imprimer",
      copied: "Copi\xE9",
      copy: "Copier",
      download: "T\xE9l\xE9charger"
    },
    warning: "Conservez votre code de r\xE9cup\xE9ration dans un endroit s\xFBr. Ces codes sont le dernier recours pour acc\xE9der \xE0 votre compte en cas de perte de votre second facteur. Si vous ne pouvez pas trouver ce code, <0>vous perdrez</0> l'acc\xE8s au compte.",
    subtitle: "Ne perdez jamais votre code de r\xE9cup\xE9ration.",
    title: "Code de r\xE9cup\xE9ration"
  },
  verify_mail: {
    abort: "Je le ferai plus tard",
    step: {
      sent_email: {
        button: "V\xE9rifiez le code",
        success: {
          message: "Votre e-mail est maintenant v\xE9rifi\xE9",
          title: "V\xE9rification termin\xE9e"
        },
        description: "\xC9crivez le code que vous venez de recevoir par email",
        subtitle: "V\xE9rifiez votre adresse e-mail"
      },
      choose_method: {
        button: "Envoyer le code",
        success: {
          message: "Un email pour terminer votre v\xE9rification vous a \xE9t\xE9 envoy\xE9",
          title: "Code envoy\xE9"
        },
        description: "Entrez votre adresse e-mail pour la v\xE9rifier",
        subtitle: "V\xE9rifiez votre adresse e-mail"
      }
    },
    title: "Terminez votre configuration"
  },
  otp: {
    failed: {
      title: "Erreur lors de la configuration de l'appareil OTP"
    },
    button: {
      abort: "Je le ferai plus tard",
      verify: "V\xE9rifiez mon appareil avec TOPT"
    },
    step: {
      type_code: "Lorsque l'OTP est pr\xEAt, v\xE9rifiez en tapant votre TOTP",
      scan_qr: "Scannez ce code QR pour configurer votre appareil de confiance via votre application OTP",
      trusted_device: "Ouvrez votre application OTP pr\xE9f\xE9r\xE9e sur votre appareil de confiance"
    },
    legend: "(T)OTP*: Mot de passe \xE0 usage unique (bas\xE9 sur le temps)",
    subtitle: "Authentificateur \xE0 double facteur pour mieux prot\xE9ger les donn\xE9es.",
    title: "Configurer votre OTP"
  },
  forgot_password: {
    success: {
      message: "Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.",
      title: "Mot de passe chang\xE9 avec succ\xE8s"
    },
    button: {
      cancel: "Annuler la r\xE9cup\xE9ration",
      verify: "V\xE9rifiez mon code",
      send: "Envoyez mon code",
      save: "Enregistrer mon mot de passe"
    },
    last_step: "Vous pouvez maintenant changer votre mot de passe pour un nouveau :",
    subtitle: "Ne paniquez pas, tout ira bien...",
    title: "Mot de passe oubli\xE9"
  }
};
var funds = {
  financials: {
    portfolio: {
      r_other_cost_price: "Autres",
      r_plots_cost_price: "Parcelles",
      r_other_market_value: "Autres",
      r_plots_market_value: "Parcelles",
      computed_market_value_by_m2: {
        title: "Valeur marchande par m\xB2"
      },
      computed_market_value: {
        title: "Valeur marchande des b\xE2timents"
      },
      c_cost_price_by_m2: {
        title: "Prix de revient par m\xB2"
      },
      c_cost_price: {
        title: "Prix de revient des b\xE2timents"
      },
      r_cost_price_q3: "Q3",
      r_commercial_market_value: "Commercial",
      c_market_value_q1: "Q1",
      c_market_value_median: "M\xE9diane",
      c_market_value_q3: "Q3",
      c_mixed_market_value: "Mixte",
      c_total_market_value: "Total",
      c_commercial_market_value: "Commercial",
      c_market_value_by_m2: {
        title: "Valeur marchande par m\xB2 estim\xE9e"
      },
      c_residential_market_value: "R\xE9sidentiel",
      r_market_value_median: "M\xE9diane",
      c_market_value: {
        title: "Valeur v\xE9nale des b\xE2timents"
      },
      r_market_value_by_m2: {
        title: "Valeur marchande par m\xB2 rapport\xE9e"
      },
      r_total_market_value: "Total",
      r_market_value_q1: "Q1",
      r_mixed_market_value: "Mixte",
      r_residential_market_value: "R\xE9sidentiel",
      r_market_value: {
        title: "Valeur marchande des b\xE2timents d\xE9clar\xE9s"
      },
      r_cost_price_median: "M\xE9diane",
      r_market_value_q3: "Q3",
      r_mixed_cost_price: "Mixte",
      r_cost_price_by_m2: {
        title: "Prix co\xFBtant par m\xB2 rapport\xE9"
      },
      r_cost_price_q1: "Q1",
      r_total_cost_price: "Total",
      r_cost_price: {
        title: "Prix de revient des b\xE2timents signal\xE9s"
      },
      r_residential_cost_price: "R\xE9sidentiel",
      r_commercial_cost_price: "Commercial",
      title: "Valeur du portefeuille"
    },
    market_value: {
      title: "Valeur marchande"
    },
    cost_price: {
      title: "Prix de revient"
    },
    rental_income: {
      r_other_net_rent: "Autres",
      r_plots_net_rent: "Parcelles",
      computed_by_m2: {
        title: "Revenu locatif par m\xB2"
      },
      computed: {
        title: "Revenu locatif"
      },
      c_net_rent_q3: "Q3",
      c_net_rent_median: "M\xE9diane",
      c_mixed_net_rent: "Mixte",
      c_total_net_rent: "Total",
      c_resiential_net_rent: "R\xE9sidentiel",
      c_commercial_net_rent: "Commercial",
      c_net_rent_q1: "Q1",
      estimated_by_m2: {
        title: "Revenu locatif par m\xB2"
      },
      reported_by_m2: {
        title: "Revenu locatif par m\xB2 d\xE9clar\xE9"
      },
      estimated: {
        title: "Revenu locatif estim\xE9"
      },
      r_net_rent_median: "M\xE9diane",
      r_net_rent_q1: "Q1",
      r_net_rent_q3: "Q3",
      r_total_net_rent: "Total",
      r_mixed_net_rent: "Mixte",
      r_resiential_net_rent: "R\xE9sidentiel",
      r_commercial_net_rent: "Commercial",
      reported: {
        title: "Revenus locatifs d\xE9clar\xE9s"
      },
      title: "Revenus locatifs"
    },
    evolution: {
      chart: {
        title: "Graphique"
      },
      title: "\xC9volution"
    },
    metrics: {
      balance_sheet: {
        chart_title: "Bilans"
      },
      income_statement: {
        chart_title: "\xC9tat des r\xE9sultats",
        title: "Indicateurs de l'\xE9tat des r\xE9sultats"
      },
      r_estimated_liquidation_taxes: "Imp\xF4ts en cas de liquidation estim\xE9s",
      c_weighted_average_cost_of_debt: "Taux d'int\xE9r\xEAt moyen pond\xE9r\xE9",
      c_earnings_per_share: "R\xE9sultat par action",
      c_weighted_average_maturity: "Maturit\xE9 moyenne pond\xE9r\xE9e",
      c_current_liabilities: "Passifs courants",
      c_revenue_per_share: "Chiffre d'affaires par action",
      c_current_ratio: "Ratio de liquidit\xE9",
      c_operating_profit: "R\xE9sultat d'exploitation",
      c_ebit_margin: "EBIT marge",
      c_loan_to_value: "Ratio pr\xEAt-valeur",
      c_debt_ratio: "Ratio d'endettement",
      c_total_cash_per_share: "Total de liquidit\xE9s par action",
      liquidity: {
        title: "Mesures de liquidit\xE9"
      },
      c_payout_ratio: "Ratio de distribution",
      c_price_to_book_value: "Valeur comptable par rapport au prix",
      c_net_initial_yield: "Rendement initial net",
      c_pe_ratio: "P/E ratio",
      c_roa: "ROA",
      c_roce: "ROCE",
      c_roe: "ROE",
      five_years_avg_dividend_yield: "Rendement moyen de dividende sur 5 ans",
      r_payout_ratio: "Rapport de distribution",
      c_agio_at_year_end: "Agio \xE0 la fin de l'ann\xE9e",
      quantitative_valuation_ratios: {
        title: "Ratios d'\xE9valuation quantitative"
      },
      r_total_dividend: "Total dividende",
      m_dividend_yield: "Rendement du dividende",
      r_dividend_yield: "Rendement du dividende",
      r_roic: "ROIC",
      r_performance: "Performance",
      dividends: {
        title: "Dividendes"
      },
      r_dividend_from_capital_gains: "Dividende provenant des plus-values",
      r_wault: "WAULT",
      r_dividend_from_direct_rental_income: "Dividende provenant de revenus locatifs directs",
      r_dividend_from_indirect_rental_income: "Dividende provenant de revenus locatifs indirects",
      r_ebit_margin: "marge EBIT",
      r_roi: "ROI",
      ratios: {
        title: "Ratios"
      },
      r_roe: "ROE",
      r_debt_ratio: "Ratio d'endettement",
      r_rental_losses: "Perte de location",
      title: "Mesures financi\xE8res"
    },
    side_menu: {
      hover_label: "Variation"
    },
    report: {
      semi_annual: "semi-annuel",
      annual: "annuel"
    },
    stock: {
      evolution: {
        title: "\xC9volution"
      },
      hist_volatility_250_days: "Volatilit\xE9 Historique 1-An",
      sharpe_ratio: "Ratio de Sharpe",
      hist_volatility_180_days: "Volatilit\xE9 historique sur 6 mois",
      alpha: "Alpha (5Y Mensuel)",
      hist_volatility_30_days: "Volatilit\xE9 historique 1 mois",
      r2: "R\xB2",
      beta: "Beta (5Y Mensuel)",
      hist_volatility_90_days: "Volatilit\xE9 Historique 3 Mois",
      three_months_avg_volume: "Moyenne du volume sur 3 mois",
      five_days_avg_volume: "Volume moyen 5 jours",
      six_months_avg_volume: "Moyenne du volume sur 6 mois",
      one_year_avg_volume: "Moyenne du volume 1 an",
      one_month_avg_volume: "Moyenne du volume 1 mois",
      risk: {
        title: "Statistiques de Risque"
      },
      unit_outstanding: "Actions en circulation",
      turnover: "Chiffre d'affaires",
      volume: "Dernier volume",
      fifty_two_weeks_low: "52 semaines bas",
      trade: {
        title: "Statistiques commerciales"
      },
      fifty_two_weeks_high: "52 semaines haut",
      five_years_return: "5 ans",
      six_months_return: "6-Mois",
      one_year_return: "1-An",
      three_years_return: "3 ans",
      three_months_return: "3 mois",
      one_month_return: "1-Mois",
      ytd_net_return: "Ann\xE9e \xE0 ce jour (YTD)",
      qtd_net_return: "Quart jusqu'\xE0 la date (QTD)",
      mtd_net_return: "Mois \xE0 ce jour (MTD)",
      history: {
        title: "Historique des prix des actions"
      },
      title: "Performance boursi\xE8re"
    },
    stock_performance: {
      title: "Performance des actions"
    },
    fees: {
      r_max_share_buyback_commissions: "Commission de rachat d'actions",
      r_max_share_issue_commissions: "Commission d'\xE9mission d'actions",
      r_max_construction_and_works_fees: "Construction et travaux",
      r_max_property_management_fees: "Frais de gestion immobili\xE8re",
      r_max_custodian_bank_fees: "Frais de banque d\xE9positaire",
      r_max_purchase_and_sale_fees: "Achat et vente de biens immobiliers",
      r_ter_mv: "TERrefMV",
      maximum: {
        title: "Frais Maximum"
      },
      r_ter_nav: "TERrefNAV",
      r_max_management_fees: "Frais de gestion",
      r_ter_gav: "TERrefGAV",
      r_share_issue_commissions: "Commission d'\xE9mission d'actions",
      r_property_management_fees: "Frais de gestion immobili\xE8re",
      r_construction_and_works_fees: "Construction et travaux",
      r_custodian_bank_fees: "Frais de banque d\xE9positaire",
      r_management_fees: "Frais de gestion",
      r_share_buyback_commissions: "Commission de rachat d'actions",
      r_purchase_and_sale_fees: "Achat et vente de biens immobiliers",
      effective: {
        title: "Frais Efficaces"
      },
      title: "Frais"
    },
    performance: {
      title: "Performance",
      r_tga_before_distribution: "TGA avant distribution",
      r_tga_after_distribution: "TGA apr\xE8s distribution",
      r_market_cap: "Capitalisation boursi\xE8re",
      r_tna_after_distribution: "TNA apr\xE8s distribution",
      c_total_agio_at_year_end: "Agio",
      r_tna_before_distribution: "TNA avant distribution",
      m_market_cap: "Capitalisation boursi\xE8re",
      current_total_value: {
        title: "Valeur totale actuelle"
      },
      r_agio_at_year_end: "Agio",
      c_nav_after_distribution: "NAV apr\xE8s distribution",
      c_total_current_agio_after_distribution: "Total agio apr\xE8s distribution",
      year_end_total_value: {
        title: "Valeur totale \xE0 la fin de l'ann\xE9e"
      },
      c_total_current_agio_compounded: "Total agio compos\xE9",
      c_tna_compounded: "TNA compos\xE9",
      c_tga_compounded: "TGA compos\xE9",
      c_nav_compounded: "NAV compos\xE9",
      c_gav_compounded: "GAV compos\xE9",
      r_nav_before_distribution: "NAV avant distribution",
      year_end_value_per_share: {
        title: "Valeur par action \xE0 la fin de l'ann\xE9e"
      },
      c_gav_after_distribution: "GAV apr\xE8s distribution",
      r_share_price: "Prix de l'action",
      r_gav_before_distribution: "GAV avant distribution",
      m_dividend_yield: "Rendement du dividende",
      c_current_agio_after_distribution: "Agio apr\xE8s distribution",
      c_current_agio_compounded: "Agio compos\xE9",
      current_value_per_share: {
        title: "Valeur actuelle par action"
      },
      m_share_price: "Prix de l'action"
    },
    debts: {
      title: "Structure de la dette"
    },
    tile: {
      rental_income: "Revenu locatif",
      key_performance: "Performance cl\xE9",
      key_financials_per_share: "Performance financi\xE8re (par action)",
      evolution: "\xC9volution",
      key_financials: "Performance financi\xE8re (totale)",
      return: "Retour",
      charges: "Charges des locataires",
      losses: "pertes",
      fees: "Frais"
    },
    trading: {},
    highlights: {},
    revenue: {
      title: "Revenu"
    },
    charges: {
      title: "Charges locatives"
    },
    title: "Finances"
  },
  debt: {
    title: "Dette"
  },
  overview: {
    net_initial_yield: "Rendement initial net",
    esg_rating: "\xC9valuation PRESS",
    ter_gav: "TERrefGAV",
    roa: "ROA",
    market_cap: "Capitalisation boursi\xE8re",
    total_co2: "CO\u2082",
    total_net_assets: "TNA",
    net_rent: "Revenu locatif",
    debt_ratio: "Ratio d'endettement",
    agio: "Agio"
  },
  environmental: {
    share: {
      r_coverage_share_fossil: "Couverture",
      r_share_fossil: "Partagez le fossile",
      coverage: "Taux de couverture",
      non_fossil: "Partager non fossile",
      fossil: "Partager fossile"
    },
    co2: {
      r_coverage_co2: "Couverture",
      title: "\xC9missions de CO\u2082",
      co2_scope_1_2_per_m2: {
        description: "Les \xE9missions de CO\u2082 sont calcul\xE9es en utilisant l'intensit\xE9 \xE9nerg\xE9tique multipli\xE9e par le coefficient REIDA pour chaque b\xE2timent. Les \xE9missions globales de CO\u2082 pour le v\xE9hicule sont ensuite calcul\xE9es avec une moyenne pond\xE9r\xE9e des b\xE2timents, o\xF9 chaque b\xE2timent est pond\xE9r\xE9 en fonction de sa surface de chauffage.",
        key: "CO\u2082 (scope 1 + 2)"
      },
      r_co2: "Total des \xE9missions de CO\u2082",
      total_co2_scope_1_2_33: "Total CO\u2082 (scope 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (scope 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (scope 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (scope 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (scope 1 + 2 + 3.3)",
      r_co2_per_m2: "\xC9missions de CO\u2082"
    },
    idc: {
      r_coverage_idc: "Couverture",
      idc_per_m2: {
        description: "Intensit\xE9 \xE9nerg\xE9tique mesure la consommation d'\xE9nergie estim\xE9e par m\xE8tre carr\xE9 de surface de chauffage sur une ann\xE9e pour chaque b\xE2timent dans le v\xE9hicule d'investissement. L'intensit\xE9 \xE9nerg\xE9tique globale du v\xE9hicule est ensuite calcul\xE9e avec une moyenne pond\xE9r\xE9e des b\xE2timents, chaque b\xE2timent \xE9tant pond\xE9r\xE9 en fonction de sa surface de chauffage.",
        key: "Intensit\xE9 \xE9nerg\xE9tique"
      },
      title: "Intensit\xE9 \xE9nerg\xE9tique",
      r_idc: "Total d'\xE9nergie",
      total_idc: "Total \xE9nergie",
      r_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique"
    },
    tile: {
      share: "Part de l'\xE9nergie",
      environmental_risks: "Risques environnementaux",
      energy_source: "Source d'\xE9nergie",
      solar: "Solaire",
      climate_risk_index: "Indice de risque climatique"
    },
    evolution: {
      chart: {
        title: "Graphique"
      },
      title: "\xC9volution"
    },
    climate: {
      risk: {
        description: "L'indice de risque climatique est bas\xE9 sur l'exposition des b\xE2timents au sein des v\xE9hicules d'investissement \xE0 diff\xE9rents risques environnementaux. Chaque risque a un poids \xE9gal dans l'indice, tandis que les b\xE2timents sont pond\xE9r\xE9s par leur surface de chauffage pour l'agr\xE9gation au niveau du v\xE9hicule d'investissement."
      }
    },
    total_estimated_idc: "Total de l'intensit\xE9 \xE9nerg\xE9tique",
    estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2",
    total_estimated_co2_emission: "Total CO\u2082",
    estimated_co2_emission_per_m2: "CO\u2082 par m\xB2",
    press_score: "Score de PRES environnemental :",
    solar_existing_per_m2: "Puissance solaire install\xE9e par m\xB2",
    title: "Environnemental",
    building_with_solar_existing: "B\xE2timents avec des panneaux solaires",
    energy: {
      title: "\xC9nergie"
    },
    hail_hazard_10_years: "Taille de la gr\xEAle",
    overbank_sedimentation: "S\xE9dimentation en dehors des berges",
    risk: {
      title: "Risque"
    },
    landslides: "Glissements de terrain",
    floods_50_years: "Inondations",
    solar_existing: "\xC9nergie solaire install\xE9e",
    sia261: "SIA261",
    rockfalls: "Chutes de pierres",
    debris_flow: "D\xE9bris fluant",
    avalanches: "Avalanches",
    solar_potential: "Total solaire potentiel"
  },
  social: {
    transport_accessibility: {
      title: "Accessibilit\xE9",
      transport_accessibility: {
        description: "Moyenne du score d'accessibilit\xE9 par transport public selon l'ARE, pour chaque b\xE2timent",
        key: "Accessibilit\xE9 par les transports en commun",
        title: "Par train"
      },
      road_accessibility: {
        description: "Moyenne du score d'accessibilit\xE9 par route selon l'ARE, pour chaque b\xE2timent",
        key: "Accessibilit\xE9 par la route",
        title: "Par la route"
      }
    },
    well_being: {
      title: "Bien-\xEAtre",
      green_density: {
        description: "La densit\xE9 verte contient une moyenne de la part des hectares environnants contenant une majorit\xE9 d'espace vert pour chaque b\xE2timent, selon l'utilisation des terres (NOAS04)",
        key: "Densit\xE9 verte",
        title: "V\xE9g\xE9tation"
      },
      public_utility_density: {
        description: "Cela mesure la part des b\xE2timents d'utilit\xE9 publique (h\xF4pitaux, \xE9tablissements de soins pour personnes \xE2g\xE9es, \xE9coles\u2026) dans le portefeuille du v\xE9hicule",
        key: "Part des b\xE2timents d'utilit\xE9 publique",
        title: "B\xE2timents d'utilit\xE9 publique"
      }
    },
    density: {
      title: "Densit\xE9",
      job_density: {
        key: "Densit\xE9 des emplois",
        description: "La densit\xE9 d'emplois mesure la densit\xE9 moyenne d'emplois dans les hectares environnants, selon STATENT, par b\xE2timent",
        title: "Densit\xE9 d'emplois"
      },
      population_density: {
        description: "La densit\xE9 de population mesure la densit\xE9 de population moyenne dans les hectares environnants, selon STATPOP, par b\xE2timent",
        key: "Densit\xE9 de population",
        title: "Density de population"
      }
    },
    accessibility: {
      primary_education: "\xC9ducation primaire",
      shops: "Boutiques",
      cultural_venues: "Espaces culturels",
      transport_train: "Train",
      transport_bus: "Bus",
      restaurants: "Restaurants",
      score: "Score",
      early_years: "Premi\xE8res ann\xE9es",
      secondary_education: "\xC9ducation secondaire",
      aged_care: "Soins aux personnes \xE2g\xE9es",
      healthcare: "Soins de sant\xE9",
      mean_healthcare: "Sant\xE9",
      mean_aged_care: "Soins aux personnes \xE2g\xE9es",
      mean_secondary_education: "Enseignement secondaire",
      mean_early_years: "Premi\xE8res ann\xE9es",
      title: "Accessibilit\xE9",
      mean_score: "Score",
      mean_restaurants: "Restaurants",
      mean_transport_bus: "Bus",
      mean_cultural_venues: "Espaces culturels",
      mean_transport_train: "Train",
      mean_shops: "Magasins",
      mean_primary_education: "\xC9ducation primaire"
    },
    tile: {
      accessibility_others: "Autre (distance moyenne)",
      accessibility_transportation: "Transports en commun (distance moyenne)",
      accessibility_education: "Sant\xE9 et \xE9ducation (distance moyenne)",
      accessibility_score: "Accessibilit\xE9",
      accessibility_to_services: "Accessibilit\xE9 aux services"
    },
    press_score: "Score de PRESSE sociale :",
    promiscuity: {
      per_100_m2: "Par 100 m\xB2",
      departure_per_inhabitant: "D\xE9part par habitant",
      title: "Densit\xE9 de population",
      per_bedroom: "Par chambre",
      arrival_per_inhabitant: "Arriv\xE9e par habitant",
      tile: {
        migration: "Migration",
        occupancy: "Occupation"
      }
    },
    demographic: {
      tile: {
        ages: "Ages",
        gender: "Genre",
        foreigners: "\xC9trangers"
      },
      title: "D\xE9mographique"
    },
    title: "Social"
  },
  governance: {
    tile: {
      minergie: "Minergie",
      press_score: "PRESSION \u2013 Scores de durabilit\xE9 immobili\xE8re publique",
      certification: "Certification",
      auditors: "Auditeurs",
      valuation_expert: "Experts en \xE9valuation",
      property_management: "Gestion immobili\xE8re",
      ownership: "Propri\xE9t\xE9"
    },
    press_score: "Score de presse de la gouvernance :",
    minergie_buildings: "Proportion de b\xE2timents minergie",
    ranking: "Classement",
    esg: "ESG",
    governance: "Gouvernance",
    social: "Social",
    environmental: "Environnemental",
    custodian_bank: "Banque d\xE9positaire",
    director: "Directeur de fonds",
    manager: "Gestionnaire de fonds",
    certificates_rating: {
      title: "Certificats et \xE9valuation"
    },
    organisation: {
      title: "Organisation"
    },
    title: "Gouvernance"
  },
  composition: {
    building_groups_nb: "Nombre de groupes de b\xE2timents",
    semi_annual_report_date: "Dernier rapport (semi-annuel)",
    annual_report_date: "Dernier rapport (annuel)",
    report_date: "Dernier rapport",
    total_area: "Total de la superficie",
    lots_nb: "Nombre de parcelles",
    lot_area: "Zone de trac\xE9",
    office_area: "Total de la surface de bureau",
    fund_age: "\xC2ge du portefeuille immobilier",
    residential_area: "Total de la superficie r\xE9sidentielle",
    industrial_area: "Total de la superficie industrielle",
    commercial_area: "Total de la surface commerciale",
    buildings_nb: "Nombre de b\xE2timents",
    report_age: "\xC2ge du rapport immobilier actuel"
  },
  environment: {
    energy_distribution: {
      value_string: "Zone chauff\xE9e",
      label_string: "Source de chaleur"
    }
  },
  description: {
    distribution: {
      value_string: "Zone",
      label_string: "Canton"
    },
    ranking: "Classement des fonds :",
    tile: {
      distribution: "Distribution",
      administrative: "Administrative",
      designation: "D\xE9signation",
      stock: "Stock",
      composition: "Composition"
    },
    composition: {
      title: "Composition"
    },
    title: "Description",
    identification: {
      title: "Identification"
    }
  },
  transactions: {
    value: {
      purchase: "achet\xE9",
      sale: "vendu"
    },
    title: "Transactions"
  },
  page: {
    title: "Tous les fonds suisses"
  },
  performance: {
    wault_suffix: "ann\xE9es",
    mtd_net_return: "Mois \xE0 ce jour (MTD)",
    debt_financing_ratio: "Ratio d'endettement",
    total_gross_assets: "Total des actifs bruts",
    return_on_equity: "Retour sur capitaux propres (ROE)",
    distribution_yield: "Rendement de distribution",
    rent_default_rate: "Perte de location",
    ebit_margin: "marge EBIT",
    yearly_management_fee: "Frais de gestion",
    total_expense_ratio_ref_mv: "Total des frais d'exploitation (TER_MV)",
    payout_ratio: "Ratio de distribution",
    relative_agio: "Agio",
    total_net_assets: "Total des actifs nets",
    market_capitalization: "Capitalisation boursi\xE8re",
    qtd_net_return: "Trimestre \xE0 date (QTD)",
    total_expense_ratio_ref_gav: "Ratio des d\xE9penses total (TER_GAV)",
    return_on_investment: "Retour sur investissement (RSI)",
    ytd_net_return: "De l'ann\xE9e \xE0 ce jour (YTD)",
    wault_label: "Moyenne pond\xE9r\xE9e de la dur\xE9e restante des baux (WAULT)",
    return_on_invested_capital: "Retour sur capital investi (ROIC)"
  },
  noright: {},
  financials_per_share: {
    share_market_price: "Prix de l'action",
    share_class_gav: "Valeur brute des actifs (VBA)",
    last_distribution: "Derni\xE8re distribution",
    distribution_value: "Valeur de distribution",
    share_class_nb: "Actions en circulation",
    share_class_nav: "Valeur nette d'inventaire (VNI)"
  },
  designation: {
    valor_no: "Num\xE9ro de valeur",
    bloomberg_ticker: "Bloomberg Ticker",
    instrument_name: "Nom de l'instrument",
    ticker: "Nom commun",
    isin: "ISIN",
    currency: "Monnaie"
  },
  stock: {
    last_distribution: "Derni\xE8re distribution",
    distribution_frequency: "Fr\xE9quence de distribution",
    units_class: "Classe d'unit\xE9s",
    units_nb: "Actions en circulation",
    year_end: "Date de fin d'ann\xE9e",
    distribution_date: "Date de distribution",
    launch_date: "Date de lancement"
  },
  administrative: {
    name: "Registre des actions",
    benchmark: "\xC9talon",
    manager: "Gestionnaire de fonds",
    custodian_bank: "Banque d\xE9positaire",
    director: "Directeur de fonds",
    domicile: "Domicile du fonds",
    benchmark_bloomberg_ticker: "Indice Ticker"
  },
  assets: {
    title: "Inventaire"
  }
};
var units = {
  r_market_value_per_m2: "CHF/m\xB2",
  r_cost_price_per_m2: "CHF/m\xB2",
  r_real_rent_per_m2: "CHF/m\xB2",
  r_other_net_rent: "CHF",
  r_plots_net_rent: "CHF",
  r_unrealised_gains_and_losses_including_var_est_liq_tax: "CHF",
  r_coverage_share_fossil: "%",
  r_share_fossil: "%",
  r_coverage_idc: "%",
  r_coverage_co2: "%",
  total_co2_scope_1_2_33: "kg/an",
  co2_scope_33_per_m2: "kg/m\xB2/an",
  co2_scope_1_2_33_per_m2: "kg/m\xB2/an",
  co2_scope_1_2_per_m2: "kg/m\xB2/an",
  idc_per_m2: "kWh/m\xB2/an",
  share: {
    coverage: "%",
    non_fossil: "%",
    fossil: "%"
  },
  total_co2_scope_1_2: "kg/an",
  total_co2_scope_1_2_3: "kg/an",
  total_idc: "kWh/an",
  co2_scope_2_per_m2: "kg/m\xB2/an",
  total_co2_scope_1: "kg/an",
  total_co2_scope_2: "kg/an",
  total_co2_scope_33: "kg/an",
  co2_scope_1_2_3_per_m2: "kg/m\xB2/an",
  r_co2: "kg/an",
  r_co2_per_m2: "kg/m2/an",
  r_idc: "kWh/an",
  r_idc_per_m2: "kWh/m2/an",
  transport_accessibility: "pts",
  road_accessibility: "pts",
  green_density: "%",
  public_utility_density: "%",
  job_density: "FTE/ha",
  population_density: "ppl./ha",
  vwap: "CHF",
  c_market_value: "CHF",
  c_net_rent: "CHF",
  r_estimated_liquidation_taxes: "CHF",
  estimated_area: "m\xB2",
  energy: {},
  description: {},
  portfolios: {},
  funds: {
    transactions: {}
  },
  built_up: {},
  demographic: {}
};
var asset = {
  get_building_from_group: {
    empty: {
      message: "Il n'y a pas de b\xE2timent dans ce groupe",
      title: "Aucun b\xE2timent dans ce groupe"
    }
  },
  description: {
    dwellings: {
      rooms_nb: "Num\xE9ro de chambres",
      ewid: "EWID",
      surface: "Superficie",
      floor: "Sol",
      construction_date: "Date de construction",
      type: "Type",
      category: "Cat\xE9gorie",
      class: "Classe",
      status: "Statut",
      land_use: "Utilisation des terres",
      city: "Ville",
      egid: "EGID"
    },
    lots: {
      built_up_ratio: "Ratio de construction",
      built_up_area: "Zone construite",
      municipality: "Municipalit\xE9",
      egrid: "EGRID",
      surface: "Surface",
      sector: "Secteur",
      number: "Num\xE9ro",
      label: "\xC9tiquette",
      type: "Type",
      land_use: "Utilisation des terres",
      city: "Ville"
    },
    amenities: {
      dwellings: {
        imputed_mansarde: "Mansarde",
        imputed_attique: "Attique",
        imputed_balcony: "Balcon",
        imputed_cellar: "Cellier"
      },
      buildings: {
        imputed_cachet: "Cachet",
        imputed_near_transports: "Pr\xE8s des transports",
        imputed_near_highway: "Pr\xE8s de l'autoroute",
        imputed_bike_room: "Chambre \xE0 v\xE9los",
        imputed_elevator: "Ascenseur",
        fallout_shelter: "Abri antiatomique"
      }
    },
    buildings: {
      dwellings_nb: "Nombre de logements",
      floors_nb: "Nombre d'\xE9tages",
      volume: "Total volume",
      area: "Surface au sol",
      total_area: "Total de la surface",
      industrial_area: "Zone industrielle",
      commercial_area: "Zone commerciale",
      office_area: "Zone de bureau",
      residential_area: "Zone r\xE9sidentielle",
      construction_date: "date de construction",
      type: "Type",
      category: "Cat\xE9gorie",
      class: "Classe",
      status: "Statut",
      land_use: "Utilisation des terres",
      official_nb: "Num\xE9ro officiel",
      city: "Ville",
      egid: "EGID"
    }
  }
};
var table_actions = {
  add_to_portfolios: {
    title: "Ajouter les actifs s\xE9lectionn\xE9s au portefeuille"
  },
  portfolios: {
    remove: {
      confirmation: {
        title: "Supprimer les actifs s\xE9lectionn\xE9s du portefeuille actuel"
      }
    }
  },
  watchlist: {
    add: {
      button: {
        label: "Suivre les actifs s\xE9lectionn\xE9s"
      }
    },
    remove: {
      button: {
        label: "Supprimer les actifs s\xE9lectionn\xE9s de la liste de surveillance"
      }
    }
  },
  request_download: {
    csv: "T\xE9l\xE9charger le CSV des lignes s\xE9lectionn\xE9es sur la page actuelle"
  }
};
var preset = {
  modal: {
    confirm_delete: {
      cancel: "Retour",
      proceed: "Proc\xE9der",
      text: "\xCAtes-vous s\xFBr de vouloir supprimer cet \xE9cran ?",
      title: "Supprimer l'\xE9cran"
    }
  }
};
var tile = {
  wrong: {
    message: "Quelque chose ne va pas"
  },
  empty: {
    message: "Donn\xE9es manquantes"
  },
  side_menu: {
    country: "Pays",
    canton: "Canton",
    municipality: "Municipalit\xE9"
  }
};
var error = {
  something_went_wrong: {
    title: "Quelque chose a mal tourn\xE9"
  },
  email: {
    format: "Votre e-mail n'est pas bien \xE9crit"
  },
  notfound: {
    button: "Retourner",
    subtitle: "D\xE9sol\xE9, la page que vous recherchez n'existe pas.",
    title: "Page non trouv\xE9e",
    code: "Erreur 404"
  }
};
var companies = {
  button: {
    clear_fund_cache: "Vider le cache du fonds",
    clear_all_cache: "Vider tout le cache",
    clear_cache: "Effacer le cache",
    check_states: "V\xE9rifier les \xE9tats",
    give_am_rights: "Donnez des droits aux femmes",
    refresh_roles: "Rafra\xEEchir les r\xF4les",
    give_default_roles: "Attribuer des r\xF4les par d\xE9faut"
  },
  modal: {
    confirm_delete: {
      text: "\xCAtes-vous s\xFBr de vouloir supprimer cette/ces entreprise(s) ?",
      title: "Confirmer la suppression"
    }
  },
  notifications: {
    success_delete_enterprises: "Les entreprises ont \xE9t\xE9 supprim\xE9es avec succ\xE8s",
    no_slot_available: "Pas de cr\xE9neaux disponibles",
    success_create_users: "Utilisateurs cr\xE9\xE9s avec succ\xE8s"
  }
};
var balance_sheet = {
  label: {
    r_tga_before_distribution: "Total des actifs",
    r_tna_before_distribution: "TNA avant distribution",
    asset_total: "Total des actifs",
    r_total_liabilities: "Total des passifs",
    r_net_assets_before_estimated_liquidation_taxes: "Actifs nets avant imp\xF4ts de liquidation estim\xE9s",
    r_estimated_liquidation_taxes: "Taxes de liquidation estim\xE9es",
    r_other_long_term_liabilities: "Autres passifs \xE0 long terme",
    other_liabilities: "Autres passifs",
    r_total_net_assets: "Total des actifs nets",
    r_total_cash: "Total en esp\xE8ces",
    r_total_properties: "Total des propri\xE9t\xE9s",
    r_other_assets: "Autres actifs",
    r_short_term_liabilities: "Passifs \xE0 court terme",
    r_short_term_interest_bearing_mortgages: "Pr\xEAts hypoth\xE9caires \xE0 int\xE9r\xEAt \xE0 court terme",
    r_other_short_term_liabilities: "Autres passifs \xE0 court terme",
    r_long_term_liabilities: "Dettes \xE0 long terme",
    r_long_term_interest_bearing_mortgages: "Pr\xEAts hypoth\xE9caires \xE0 int\xE9r\xEAt \xE0 long terme",
    total_liabilities: "Total des passifs",
    date: "Date"
  },
  unit: {
    chf: "CHF"
  },
  legend: {
    group: {
      liabilities: "Passifs",
      asset: "Actif"
    }
  }
};
var actions = {
  screener: {
    filter_header: {
      header: "Colonnes",
      label: "D\xE9finir la visibilit\xE9 des colonnes"
    },
    per_page: {
      header: "Nombre de lignes"
    },
    preset: {
      delete: "Supprimer l'\xE9cran",
      copy: "Copier l'\xE9cran"
    },
    filter: {}
  },
  add_to_watchlist: {},
  update: {
    processing: {
      done: "L'op\xE9ration a \xE9t\xE9 effectu\xE9e avec succ\xE8s. La page sera mise \xE0 jour bient\xF4t.",
      title: "Mise \xE0 jour",
      message: "L'op\xE9ration sera faite bient\xF4t."
    }
  },
  watchlist: {
    remove: {
      button: {
        label: "Ne plus suivre"
      },
      success: {
        message: "Actif retir\xE9 de votre liste de suivi"
      }
    },
    add: {
      button: {
        label: "Suivre"
      },
      success: {
        message: "Actif ajout\xE9 \xE0 votre liste de surveillance"
      }
    }
  },
  companies: {
    create: "Cr\xE9er une entreprise",
    delete: "Supprimer"
  },
  portfolios: {
    remove: {
      success: {
        message: "{{ asset }}(s) retir\xE9(s) de votre portefeuille"
      }
    }
  },
  request_download: {
    csv: "T\xE9l\xE9charger CSV",
    message: "Demande cr\xE9\xE9e"
  },
  add_to_portfolios: {
    adding_portfolio: {
      message: "Ajouter {{asset}}(s) \xE0 votre portefeuille",
      title: "Ajouter"
    },
    success_add_portfolio: {
      message: "Ajouter {{asset}} \xE0 votre portefeuille {{portfolio_state}}",
      title: "R\xE9ussi"
    },
    success_create_portfolio: {
      title: "R\xE9ussi",
      message: "Nouveau portefeuille a \xE9t\xE9 cr\xE9\xE9"
    },
    confrm_modal: {
      title: "Les habitations \xE9taient d\xE9j\xE0 contenues",
      buttons: {
        cancel: "Retour",
        confirm: "Proc\xE9der"
      }
    },
    confirm_modal: {
      text_1: "Le portefeuille contient d\xE9j\xE0 des logements de {{building_label}}.",
      text_2: "Voulez-vous les remplacer par le b\xE2timent \xE0 la place ?"
    },
    modal: {
      portfolio_name: "Nom du portefeuille",
      add_to_existing: "Ajouter \xE0 l'existant",
      name: "Nom",
      create_button: "Cr\xE9er",
      new: "Nouveau"
    },
    title: "Ajouter au portefeuille"
  }
};
var common = {
  assets: {
    pascalcase: "Actifs"
  },
  comparables: {
    pascalcase: "Comparables"
  },
  real_estate_firm: {
    pascalcase: "Entreprise immobili\xE8re"
  },
  foundation: {
    pascalcase: "Fondation d'investissement"
  },
  unlisted_fund: {
    pascalcase: "Fonds non r\xE9pertori\xE9"
  },
  listed_fund: {
    pascalcase: "Fonds list\xE9"
  },
  transactions: {
    pascalcase: "Transactions"
  },
  keys: "cl\xE9s",
  key: "cl\xE9",
  enterprise_state: {
    state: "Type de contrat",
    ended: "Fini",
    exempt: "Exempt",
    trial_end: "Fin de l'essai",
    unpaid: "Non pay\xE9",
    subscribed: "Abonn\xE9",
    trial: "Essai",
    examination: "Examen"
  },
  developer_mode: {
    deactivated: "D\xE9activ\xE9",
    activated: "Activ\xE9",
    state: "Mode d\xE9veloppeur"
  },
  institutions: {
    pascalcase: "Institutions"
  },
  connection_lost: {
    reload_button: "Recharger",
    message_2: "Application ne peut pas fonctionner comme pr\xE9vu",
    message_3: "Veuillez recharger la page.",
    title: "Connexion perdue",
    message_1: "Vous n'\xEAtes peut-\xEAtre plus connect\xE9 \xE0 Internet."
  },
  warning_msg: "Operation sur {{ failed }} a \xE9chou\xE9.",
  warning: "Avertissement",
  financials: {
    array: {
      total: "Total",
      residential: "R\xE9sidentiel",
      office: "Bureau",
      commercial: "Commercial",
      industrial: "Industriel"
    }
  },
  slot: {
    consumer: "Consommateur",
    institutional: "Institutionnel",
    investor: "Investisseur"
  },
  role: {
    user: "Utilisateur",
    manager: "Gestionnaire",
    owner: "Propri\xE9taire"
  },
  removing: "enlever",
  error: "Quelque chose s'est pass\xE9",
  success: "R\xE9ussi",
  delete_user: "Supprimer l'utilisateur",
  cancel_button: "Annuler",
  confirm_button: "Confirmer",
  notification: {
    success: {
      title: "Succ\xE8s"
    }
  },
  lots: {
    pascalcase: "Parcelles",
    lowercase: "parcelles"
  },
  error_message: "Quelque chose s'est pass\xE9",
  buildings: {
    lowercase: "b\xE2timents",
    pascalcase: "B\xE2timents"
  },
  dwellings: {
    lowercase: "logements",
    pascalcase: "Dwellings"
  },
  portfolios: {
    pascalcase: "Portefeuilles"
  },
  funds: {
    pascalcase: "Fonds"
  }
};
var income_statement = {
  label: {
    r_provisions_for_future_works: "Dispositions pour les travaux futurs",
    r_unrealised_gains_and_losses_including_var_est_liq_tax: "Gains/pertes non r\xE9alis\xE9s y compris la variation des taxes de liquidation",
    r_absolute_custodian_bank_commission_fees: "Frais de commission de banque d\xE9positaire absolus",
    r_absolute_property_management_fees: "Frais de gestion immobili\xE8re absolus",
    r_absolute_directors_fees: "Honoraires absolus des administrateurs",
    r_absolute_legal_fees: "Frais juridiques absolus",
    r_net_income_available_for_distribution: "Revenu net disponible pour distribution",
    r_absolute_custodian_bank_distribution_fees: "Frais de distribution absolus de banque d\xE9positaire",
    r_interest_on_building_rights: "Int\xE9r\xEAt sur les droits de construction",
    r_total_provisions: "Total des provisions",
    r_provisions_for_future_work: "Dispositions pour le travail futur",
    r_provisions_for_doubtful_debtors: "Provisions pour cr\xE9ances douteuses",
    r_ordinary_maintenance_and_repairs_expenses: "D\xE9penses d'entretien et de r\xE9parations ordinaires",
    r_extraordinary_maintenance_and_repairs_expenses: "D\xE9penses extraordinaires de maintenance et de r\xE9parations",
    r_administration_expenses: "D\xE9penses administratives",
    r_other_administration_expenses: "Autres frais administratifs",
    r_total_expenses: "Total des d\xE9penses",
    r_total_revenue: "Total des revenus",
    other_net_income_available_for_distribution: "Autre revenu net disponible pour distribution",
    r_capital_distribution: "Distribution du capital",
    r_dividend_distribution: "Distribution de dividendes",
    r_retained_earnings: "R\xE9sultats non distribu\xE9s",
    r_ordinary_income_carried_forward_from_previous_financial_year: "Revenu ordinaire report\xE9 de l'exercice financier pr\xE9c\xE9dent",
    r_net_income: "Revenu net",
    r_realised_capital_gains_and_losses: "Gains et pertes en capital r\xE9alis\xE9s",
    r_unrealised_capital_gains_and_losses: "Gains et pertes en capital non r\xE9alis\xE9s",
    r_realised_income: "Revenu r\xE9alis\xE9",
    r_total_income: "Total des revenus",
    r_other_expenses: "Autres d\xE9penses",
    r_absolute_custodian_bank_fees: "Frais de banque d\xE9positaire absolus",
    r_market_maker_fees: "Frais de teneur de march\xE9",
    r_property_management_expenses: "D\xE9penses de gestion immobili\xE8re",
    r_property_tax: "Taxe fonci\xE8re",
    r_profit_and_capital_taxes: "Taxe sur les b\xE9n\xE9fices et les capitaux",
    r_taxes: "Taxes",
    r_administration_of_buildings: "Administration des b\xE2timents",
    r_valuation_expenses: "D\xE9penses de valorisation",
    r_auditing_expenses: "Audit des d\xE9penses",
    r_valuation_and_auditing_expenses: "Frais de valorisation et d'audit",
    r_absolute_management_fees: "Frais de gestion absolus",
    r_rental_income: "Revenu locatif",
    r_revenue_from_postal_and_bank_accounts: "Revenus des comptes postaux et bancaires",
    r_negative_interest: "Int\xE9r\xEAt n\xE9gatif",
    r_other_revenue: "Autres revenus",
    r_mortgage_interest: "Int\xE9r\xEAt hypoth\xE9caire",
    r_maintenance_and_repairs_expenses: "Frais de maintenance et de r\xE9parations",
    r_other_interest: "Autre int\xE9r\xEAt",
    r_total_interest: "Total des int\xE9r\xEAts",
    r_building_expenses: "Frais de construction",
    r_maintenance_costs: "Co\xFBts de maintenance",
    total_net_income_available_for_distribution: "Total des revenus nets disponibles pour distribution",
    total_expenses: "Total des d\xE9penses",
    total_revenues: "Total des revenus",
    retained_earnings: "R\xE9sultats non distribu\xE9s",
    rental_income: "Revenu locatif",
    revenue_from_postal_and_bank_accounts: "Revenus des comptes postaux et bancaires",
    negative_interest: "Int\xE9r\xEAt n\xE9gatif",
    other_revenue: "Autres revenus",
    interest_expenses: "D\xE9penses d'int\xE9r\xEAts",
    maintenance_costs: "Co\xFBts de maintenance",
    taxes: "Taxes",
    valuation_and_auditing_expenses: "Frais de valorisation et d'audit",
    other_expenses: "Autres d\xE9penses",
    net_income: "Revenu net",
    realised_gains_losses: "Gains/pertes r\xE9alis\xE9s",
    realised_income: "Revenu r\xE9alis\xE9",
    unrealised_gains_losses_including_liquidation_taxes_variation: "Gains/pertes non r\xE9alis\xE9s y compris la variation des taxes de liquidation",
    total_income: "Total des revenus",
    ordinary_income_carried_forward: "Revenu ordinaire report\xE9",
    capital_distribution: "Distribution du capital",
    dividend_distribution: "Distribution de dividendes",
    year: "Ann\xE9e"
  },
  legend: {
    group: {
      distribution_of_realised_income: "Distribution des revenus r\xE9alis\xE9s",
      income_statement: "\xC9tat des r\xE9sultats"
    }
  },
  unit: {
    chf: "CHF"
  }
};
var global = {
  chart: {
    legend: {
      none: "No chauffage",
      m_market_cap: "Capitalisation boursi\xE8re",
      m_share_price: "Prix de l'action",
      co_2_scope_1_2_33_per_m_2: "CO\u2082 scope 1 + 2 + 3.3",
      total_co_2_scope_1_2_33: "Total CO\u2082 scope 1 + 2 + 3.3",
      total_co_2_scope_1_2: "Total CO\u2082 scope 1 + 2",
      total_idc: "Total \xC9nergie",
      idc_per_m_2: "Intensit\xE9 \xE9nerg\xE9tique",
      co_2_scope_1_per_m_2: "CO\u2082 p\xE9rim\xE8tre 1",
      co_2_scope_2_per_m_2: "CO\u2082 champ 2",
      co_2_scope_33_per_m_2: "CO\u2082 scope 3.3",
      co_2_scope_1_2_per_m_2: "CO\u2082 scope 1 + 2",
      total_co_2_scope_33: "Total CO\u2082 scope 3.3",
      total_co_2_scope_2: "Total CO\u2082 scope 2",
      total_co_2_scope_1: "Total CO\u2082 scope 1",
      r_cost_price: "Prix de revient des b\xE2timents",
      r_share_price: "Prix de l'action",
      r_target_rent: "Cible de revenu locatif",
      r_tna_before_distribution: "TNA avant distribution",
      r_tga_before_distribution: "TGA avant distribution",
      r_market_cap: "Capitalisation boursi\xE8re",
      r_debt_ratio: "Ratio d'endettement",
      r_ebit_margin: "Ebit margin",
      r_rental_losses: "Perte de location",
      r_roe: "ROE",
      r_dividend_yield: "Rendement du dividende",
      r_payout_ratio: "Ratio de distribution",
      r_nav_before_distribution: "NAV avant distribution",
      r_market_value: "Valeur marchande des b\xE2timents",
      c_market_value: "Valeur marchande des b\xE2timents",
      c_net_rent: "Revenu locatif",
      r_real_rent: "Vraie loyer",
      liabilities: "Passifs",
      equity: "\xC9quit\xE9",
      asset: "Actif",
      default: "Montant",
      roa: "ROA",
      surface: "Surface",
      co_2_expected: "CO\u2082 (pr\xE9vu)",
      co_2_listed: "CO\u2082 (r\xE9pertori\xE9)",
      gav: "GAV",
      nav: "NAV",
      rent_expected: "Revenu locatif (pr\xE9vu)",
      rent_listed: "Revenu locatif (r\xE9pertori\xE9)",
      realised_gains_losses: "Gains/pertes r\xE9alis\xE9s",
      realised_income: "Revenus r\xE9alis\xE9s",
      net_income_available_for_distribution: "Revenu net disponible pour distribution",
      ordinary_income_carried_forward: "Revenu ordinaire report\xE9",
      total_income: "Total des revenus",
      unrealised_gains_losses_including_liquidation_taxes_variation: "Gains/pertes non r\xE9alis\xE9s y compris la variation des imp\xF4ts de liquidation",
      net_income: "Revenu net",
      expenses: "D\xE9penses",
      revenues: "Revenus",
      total_net_asset: "Total des actifs nets",
      other_long_term_liabilities: "Autres passifs \xE0 long terme",
      long_term_interest_bearing_mortgages: "Pr\xEAts hypoth\xE9caires \xE0 int\xE9r\xEAt \xE0 long terme",
      long_term_liabilities: "Passifs \xE0 long terme",
      other_short_term_liabilities: "Autres passifs \xE0 court terme",
      short_term_interest_bearing_mortgages: "Pr\xEAts hypoth\xE9caires \xE0 int\xE9r\xEAt \xE0 court terme",
      short_term_liabilities: "Passifs \xE0 court terme",
      other_asset: "Autre actif",
      total_properties: "Total des propri\xE9t\xE9s",
      other: "Autre",
      non_fossil: "Non fossil",
      fossil: "Fossile",
      tessin: "Ticino",
      zentralschweiz: "Zentralschweiz",
      espace_mitelland: "Espace Mitelland",
      ostschweiz: "Ostschweiz",
      region_lemanique: "R\xE9gion l\xE9manique",
      zurich: "Z\xFCrich",
      nordwestschweiz: "Nord-ouest de la Suisse",
      cash_holding_and_balances_at_sight: "Liquidit\xE9s d\xE9tenues et soldes \xE0 vue",
      woman: "Femme",
      man: "Homme",
      legend: "L\xE9gende",
      amount: "Montant",
      date: "Date"
    },
    tooltip: {
      potential: "Pr\xE9vu",
      real: "\xC9num\xE9r\xE9"
    }
  },
  assets: {
    dwelling: "habitation",
    building: "b\xE2timent"
  }
};
var asset_page = {
  environmental: {
    co2: {
      total_co2_scope_1_2_33: "Total CO\u2082 (scope 1 + 2 + 3.3)",
      total_co2_scope_1_2: "Total CO\u2082 (scope 1 + 2)",
      co2_scope_33_per_m2: "CO\u2082 (scope 3.3)",
      co2_scope_2_per_m2: "CO\u2082 (scope 2)",
      co2_scope_1_per_m2: "CO\u2082 (scope 1)",
      co2_scope_1_2_33_per_m2: "CO\u2082 (scope 1 + 2 + 3.3)",
      co2_scope_1_2_per_m2: {
        key: "CO\u2082 (scope 1 + 2)",
        description: "Les \xE9missions de CO\u2082 sont calcul\xE9es en utilisant l'intensit\xE9 \xE9nerg\xE9tique multipli\xE9e par le coefficient REIDA pour chaque b\xE2timent."
      }
    },
    idc: {
      idc_per_m2: {
        description: "L'intensit\xE9 \xE9nerg\xE9tique mesure la consommation \xE9nerg\xE9tique estim\xE9e par m\xE8tre carr\xE9 de surface de chauffage sur une ann\xE9e dans le b\xE2timent.",
        key: "Intensit\xE9 \xE9nerg\xE9tique"
      },
      total_idc: "Total \xE9nergie"
    },
    risk: {
      chart: {
        description: "l'indice de risque climatique est calcul\xE9 en fonction de l'exposition \xE0 diff\xE9rents risques environnementaux. Chaque risque a un poids \xE9gal dans l'indice.",
        suffix: "/10"
      },
      risk: {},
      climate_risk_index: {}
    },
    energy: {
      idc: {
        estimated_idc: "Intensit\xE9 \xE9nerg\xE9tique estim\xE9e",
        total_official_idc: "Total intensit\xE9 \xE9nerg\xE9tique (officiel)",
        estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2",
        total_estimated_idc: "Total d'intensit\xE9 \xE9nerg\xE9tique",
        official_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2 (officiel)"
      },
      co2: {
        estimated_co2: "CO\u2082 estim\xE9",
        official_co2_emission_per_m2: "CO\u2082 par m\xB2 (officiel)",
        total_official_co2_emission: "Total CO\u2082 (officiel)",
        estimated_co2_emission_per_m2: "CO\u2082 par m\xB2",
        total_estimated_co2_emission: "Total CO\u2082",
        title: "\xC9missions de CO\u2082"
      },
      heating: {
        energy_generator_water_1: "G\xE9n\xE9rateur d'eau chaude",
        energy_source_water_1: "Source d'\xE9nergie de l'eau chaude",
        energy_source_heating_1: "Source d'\xE9nergie de chauffage",
        energy_generator_heating_1: "G\xE9n\xE9rateur de chauffage",
        title: "Production de chaleur"
      },
      solar: {
        solar_existing: "\xC9nergie solaire install\xE9e",
        solar_potential: "Potentiel solaire",
        title: "Solaires"
      }
    },
    environment: {
      climate: {
        temperature: {},
        sunlight: {},
        rain: {}
      },
      pollution: {
        rail_noise_day: {},
        rail_noise_night: {},
        road_noise_day: {},
        road_noise_night: {}
      },
      title: "Environnement"
    }
  },
  social: {
    income_and_taxes: {
      income: {
        description: "Description",
        subject: "Revenu par contribuable",
        title: "Revenu",
        label: "Revenu"
      },
      profit: {
        label: "Profit"
      },
      capital: {
        label: "Capitale"
      },
      wealth: {
        label: "Richesse"
      },
      municipality_tax: {
        title: "Multiplicateurs d'imp\xF4t municipal"
      },
      canton_tax: {
        title: "Multiplicateurs d'imp\xF4ts cantonaux"
      },
      title: "Revenus et imp\xF4ts"
    },
    transportation_accessibility: {
      transport_accessibility: {
        description: "Score d'accessibilit\xE9 par les transports en commun selon l'ARE",
        label: "Accessibilit\xE9 par les transports en commun"
      },
      road_accessibility: {
        description: "Score d'accessibilit\xE9 par route selon l'ARE",
        label: "Accessibilit\xE9 par la route",
        title: "Par la route"
      },
      title: "Accessibilit\xE9"
    },
    well_being: {
      public_utility: {
        key: "Immeuble de service public",
        title: "Immeuble de service public"
      },
      title: "Bien-\xEAtre"
    },
    density: {
      population_density: {
        title: "Population densit\xE9",
        description: "La densit\xE9 de population mesure la densit\xE9 de population moyenne dans les hectares environnants, selon STATPOP",
        key: "Density de population"
      },
      title: "Densit\xE9",
      green_density: {
        description: "La densit\xE9 verte mesure la part des hectares environnants contenant une majorit\xE9 de surface verte, selon l'utilisation des sols (NOAS04)",
        title: "Verdure",
        key: "Density verte"
      },
      job_density: {
        title: "Densit\xE9 d'emplois",
        description: "La densit\xE9 d'emploi mesure la densit\xE9 d'emploi moyenne dans les hectares environnants, selon STATENT",
        key: "Densit\xE9 d'emplois"
      },
      occupancy_per_bedroom: {
        subject: "Habitants",
        title: "Occupation par chambre"
      },
      occupancy_per_m2: {
        subject: "Habitants"
      },
      occupancy_per_100_m2: {
        title: "Occupation par 100 m\xB2"
      },
      occupancy: {},
      migration: {},
      income: {}
    },
    accessibility: {
      chart: {
        suffix: "/10"
      },
      goods_and_services: {
        title: "Autre"
      },
      transportation: {
        title: "Transports en commun"
      },
      health_and_edu: {
        title: "Sant\xE9 et \xC9ducation"
      },
      title: "Distances aux biens et services",
      distance: {}
    },
    demographic: {
      migration: {
        title: "Migration"
      },
      emigration: {
        subject: "\xC9migration"
      },
      immigration: {
        subject: "Immigration"
      },
      death: {
        subject: "D\xE9c\xE8s"
      },
      birth: {
        subject: "Naissances"
      },
      vital_statistics: {
        title: "Statistiques vitales"
      },
      civil_status: {},
      foreigners: {},
      gender: {},
      ages: {}
    },
    title: "Social"
  },
  financials: {
    units: {},
    ratio_metric: {
      market_value_reported: "Valeur marchande (rapport\xE9e)",
      commercial_spaces: {
        label: "Espaces commerciaux"
      },
      dwellings: {
        label: "Dwellings"
      },
      total_tenant_charges: "Total des charges locatives",
      market_value: "Valeur marchande",
      charges_per_m2: "Charges locatives par m\xB2",
      price_per_m2: "Prix par m\xB2",
      rent_per_m2: "Revenu locatif par m\xB2",
      charges: {},
      rent: {},
      price: {}
    },
    unit: {
      chf: "CHF"
    },
    charges: {
      title: "Frais de locataire"
    },
    total: {
      label: "Total"
    },
    commercial_spaces: {},
    dwellings: {
      label: "Demeures"
    },
    performance: {
      title: "Performance"
    },
    metrics: {
      title: "M\xE9triques par m\xE8tre carr\xE9"
    },
    tile: {},
    revenue: {},
    comparables: {},
    title: "Finances"
  },
  description: {
    tile: {
      parent_description: "Description du parent",
      buildings: {
        parent_description: "Description du lot"
      },
      usage: "Usage",
      geometry: "G\xE9om\xE9trie",
      dwellings: {},
      description: "Description",
      location: "Emplacement",
      addresses: "Adresses",
      administrative_information: "Informations administratives",
      amenities: "Commodit\xE9s"
    },
    title: "Description",
    identification: {
      title: "Identification"
    },
    general_information: {
      title: "Informations g\xE9n\xE9rales"
    }
  },
  valuation: {
    cap_rate_method: {
      income_inputs: {
        other: {
          suffix: "CHF",
          label: "Autres revenus"
        },
        parking: {
          label: "Parking",
          suffix: "CHF"
        },
        title: "Entr\xE9es de revenus",
        office_net_income: {
          label: "Revenu net de bureau",
          suffix: "CHF"
        },
        commercial: {
          label: "Revenu commercial",
          suffix: "CHF"
        },
        industrial: {
          label: "Industriel",
          suffix: "CHF"
        },
        residential_net_income: {
          suffix: "CHF",
          label: "Revenu net r\xE9sidentiel"
        },
        dwelling_net_rent: {
          label: "Loyer net du logement",
          suffix: "CHF"
        }
      },
      cap_rate_inputs: {
        title: "Entr\xE9es de taux de capitalisation",
        capitalization_rate: {
          label: "Taux de capitalisation",
          suffix: "%"
        }
      },
      result: {
        total_acquisition_cost: {
          suffix: "CHF",
          label: "Co\xFBt total d'acquisition"
        },
        purchase_price: {
          label: "Prix d'achat",
          suffix: "CHF"
        },
        title: "R\xE9sultat"
      },
      title: "M\xE9thode du taux de capitalisation",
      description: {
        content: "La m\xE9thode du taux de capitalisation (cap rate) appliqu\xE9e \xE0 l'immobilier est utilis\xE9e pour estimer la valeur potentielle d'une propri\xE9t\xE9. Elle est calcul\xE9e comme le revenu locatif divis\xE9 par le rendement cible. Les taux de capitalisation ne tiennent pas compte de l'appr\xE9ciation de la valeur future ou des co\xFBts de financement.",
        title: "Description"
      }
    },
    parameters: {
      buyer_seller: {
        other_fees_chf: {
          suffix: "CHF",
          label: "Autres frais"
        },
        other_fees_pts: {
          suffix: "%",
          label: "Autres frais"
        },
        asset_manager_fees: {
          label: "Frais de gestion d'actifs",
          suffix: "%"
        },
        share_buyers_seller: {
          label: "Partage acheteur / vendeur",
          suffix: "%"
        },
        title: "Acheteur / Vendeur"
      },
      transaction_fees: {
        property_transfert_fees: {
          suffix: "%",
          label: "Frais de transfert de propri\xE9t\xE9"
        },
        title: "Frais de transaction",
        land_registration_fees: {
          label: "Frais d'enregistrement foncier",
          suffix: "%"
        },
        notary_fees: {
          label: "Frais de notaire",
          suffix: "%"
        }
      },
      due_diligence_cost: {
        other_valuations: {
          suffix: "CHF",
          label: "Autres \xE9valuations"
        },
        valuation: {
          suffix: "CHF",
          label: "\xC9valuation"
        },
        title: "Co\xFBt de la diligence raisonnable"
      },
      title: "Param\xE8tres"
    },
    qh_method: {
      title: "Quanthome's method",
      result: {
        total_acquisition_cost: {
          label: "Total des co\xFBts d'acquisition",
          suffix: "CHF"
        },
        purchase_price: {
          suffix: "CHF",
          label: "Prix d'achat"
        },
        title: "R\xE9sultat"
      },
      description: {
        title: "Description",
        content: "Les algorithmes d'apprentissage automatique de Quanthome estiment le rendement cible attendu pour le b\xE2timent et pr\xE9disent une valeur bas\xE9e sur les flux de tr\xE9sorerie estim\xE9s."
      }
    },
    title: "\xC9valuation"
  },
  governance: {
    item: {
      ownership: {},
      certification: {},
      management: {}
    },
    tile: {},
    organisation: {}
  },
  overview: {
    description: {
      market_value: "Valeur marchande",
      idc: "Intensit\xE9 \xE9nerg\xE9tique",
      net_rent: "Revenu locatif",
      co2: "CO\u2082",
      roa: "ROA",
      charges: "Charges locatives"
    }
  },
  assets: {
    title: "Inventaire"
  }
};
var portfolios = {
  environmental: {
    evolution: {
      chart: {
        title: "Graphique"
      }
    },
    estimated_co2_emission_per_m2: "CO\u2082 par m\xB2",
    total_estimated_co2_emission: "Total CO\u2082",
    estimated_idc_per_m2: "Intensit\xE9 \xE9nerg\xE9tique par m\xB2",
    total_estimated_idc: "Total d'intensit\xE9 \xE9nerg\xE9tique",
    overbank_sedimentation: "S\xE9dimentation de d\xE9bordement",
    rockfalls: "Chutes de pierres",
    solar_potential: "Total solaire potentiel",
    floods_50_years: "Inondations",
    building_with_solar_existing: "B\xE2timents avec des panneaux solaires",
    solar_existing_per_m2: "Puissance solaire install\xE9e par m\xB2",
    debris_flow: "D\xE9bris fluviatiles",
    landslides: "Glissements de terrain",
    solar_existing: "\xC9nergie solaire install\xE9e",
    avalanches: "Avalanches",
    hail_hazard_10_years_average: "Taille de la gr\xEAle"
  },
  financials: {
    evolution: {
      chart: {
        title: "Graphique"
      },
      title: "\xC9volution"
    },
    performance: {
      evolution: {
        title: "\xC9volution"
      },
      title: "Performance"
    }
  },
  environment: {
    energy_distribution: {
      value_string: "Zone chauff\xE9e",
      label_string: "Source de chaleur"
    }
  },
  description: {
    distribution: {
      value_string: "Zone",
      label_string: "Canton"
    }
  },
  noright: {},
  overview: {
    type: {},
    potential_roa: "ROA",
    potential_charges: "Charges",
    potential_net_rent: "Revenus locatifs",
    potential_market_value: "Valeur de march\xE9"
  },
  modal: {
    confirm_delete: {
      notification: {}
    }
  },
  create_modal: {
    title: "Nouveau portefeuille"
  },
  notification: {
    success_create: {
      message: "Le portefeuille a \xE9t\xE9 cr\xE9\xE9 avec succ\xE8s",
      title: "Cr\xE9\xE9 avec succ\xE8s"
    }
  },
  widget: {
    energy: {
      title: "\xC9nergie"
    },
    risk: {
      title: "Risque"
    },
    identification: {
      title: "Identification"
    }
  },
  tiles: {
    solar: {
      title: "Solaire"
    },
    energy_source: {
      title: "Source d'\xE9nergie"
    },
    risk_index: {
      title: "Indice de risque climatique"
    },
    risks: {
      title: "Risques environnementaux"
    },
    composition: {
      title: "Composition"
    },
    designation: {
      title: "D\xE9signation"
    },
    distribution: {
      title: "Distribution"
    },
    idc_and_co2: {
      title: "Intensit\xE9 \xE9nerg\xE9tique & CO\u2082"
    }
  },
  designation: {
    updated_at: "Derni\xE8re mise \xE0 jour",
    name: "Nom de l'instrument",
    fund_age: "\xC2ge du portefeuille immobilier",
    commercial_area: "Total de la surface commerciale",
    industrial_area: "Zone industrielle totale",
    individual_dwellings_nb: "Nombre de logements individuels",
    office_area: "Zone totale de bureau",
    dwellings_nb: "Nombre de logements",
    buildings_nb: "Nombre de b\xE2timents",
    total_area: "Total de la superficie",
    residential_area: "Total de la superficie r\xE9sidentielle",
    type: "Composition d'actifs du portefeuille"
  }
};
var portfolio = {
  environmental: {
    evolution: {
      title: "\xC9volution"
    }
  }
};
var candle_stick_chart = {
  unit: {
    units: "Unit\xE9s",
    chf: "CHF"
  },
  label: {
    close: "Fermer",
    high: "Haut",
    low: "Faible",
    open: "Ouvrir",
    start_date: "D\xE9but",
    end_date: "Fin",
    volume: "Volume",
    turnover: "Chiffre d'affaires",
    date: "Date"
  }
};
var screener = {
  filters: {
    labels: {
      potential_net_rent_per_m2: "Potential loyer net par m\xB2",
      potential_market_value: "Valeur de march\xE9 potentielle (estim\xE9e)",
      potential_market_value_per_m2: "Valeur marchande potentielle par m\xB2",
      potential_charges: "Charges potentielles",
      potential_charges_per_m2: "Charges potentielles par m\xB2",
      potential_roa: "ROA",
      area: "Surface du terrain",
      potential_net_rent: "Potentiel loyer net",
      columns_to_ignore: "Colonnes",
      sort: "Tri",
      roa: "ROA",
      charges: "Charges locatives",
      price: "Prix",
      rent: "Revenu locatif",
      net_rent: "Revenu locatif",
      idc: "Intensit\xE9 \xE9nerg\xE9tique",
      co2: "CO\u2082",
      label: "\xC9tiquette",
      energy_source_heating: "Source de chauffage",
      rooms_nb: "Num\xE9ro de chambres",
      floor: "Sol",
      elevator: "Ascenseur",
      ground_area: "Zone de surface",
      floors_nb: "Nombre d'\xE9tages",
      dwellings_nb: "Nombre de logements",
      construction_year: "Ann\xE9e de construction",
      buildings_nb: "B\xE2timents num\xE9ro",
      plot_surface: "Surface de trac\xE9",
      municipality: "Municipalit\xE9",
      street: "Rue",
      locality: "Localit\xE9",
      region: "R\xE9gion",
      canton: "Canton"
    },
    multi_select: {
      options: {
        mixed: "Mixte",
        residential: "R\xE9sidentiel",
        commercial: "Commercial",
        listed_fund: "Fonds r\xE9pertori\xE9s",
        unlisted_fund: "Fonds non r\xE9pertori\xE9",
        real_estate_firm: "Entreprise immobili\xE8re",
        foundation: "Fondation"
      }
    },
    groups: {},
    panel: {}
  },
  tabs: {
    performance: "Performance",
    accounting: "Comptabilit\xE9",
    stock: "Performance boursi\xE8re",
    fees: "Frais",
    esg: "ESG",
    transaction: "Transaction",
    information: "Information",
    structure: "Structure",
    trading: "Commerce",
    governance: "Gouvernance",
    social: "Social",
    saved_screens: "Screens enregistr\xE9s",
    type: "Type"
  }
};
var table = {
  fund_type: {},
  fund_legal_form: {
    unlisted_fund: "Fonds non r\xE9pertori\xE9",
    foundation: "Fondation",
    real_estate_firm: "Entreprise immobili\xE8re",
    listed_fund: "Fonds list\xE9"
  },
  empty: "Pas de donn\xE9es (pour l'instant) nous y travaillons \u{1F3C3}\u200D\u2642\uFE0F"
};
var setting = {
  preference: {
    notification: {
      unsaved_screen: {
        sub: "Recevoir une notification lorsque vous avez un \xE9cran non enregistr\xE9.",
        title: "Activer la notification d'\xE9cran non enregistr\xE9"
      },
      sub: "Activer la notification pop-up n\xE9cessaire.",
      title: "Pr\xE9f\xE9rence de pop-up de notification"
    },
    save_changes: "Enregistrer les modifications",
    unsaved_screen_notification: {
      sub: "Si vous cochez cette case, vous ne recevrez plus cette notification. Vous pouvez modifier cette pr\xE9f\xE9rence sur la page des param\xE8tres.",
      title: "Ne me montrez plus cette notification"
    },
    language: {
      subtitle: "S\xE9lectionnez votre langue pr\xE9f\xE9r\xE9e.",
      title: "Langue",
      success_message: "La pr\xE9f\xE9rence de langue a \xE9t\xE9 enregistr\xE9e."
    }
  },
  profile: {
    email_preferences: {
      technical: {
        sub: "Recevez des journaux de changement lorsque l'application a de nouvelles fonctionnalit\xE9s",
        title: "Emails techniques"
      },
      marketing: {
        sub: "Recevez des nouvelles de l'entreprise",
        title: "Emails de marketing"
      },
      sub: "Changez vos pr\xE9f\xE9rences de courrier \xE9lectronique",
      title: "Email pr\xE9f\xE9rences"
    },
    notification: {
      send_email_duplicated_msg: "Un e-mail de v\xE9rification a \xE9t\xE9 envoy\xE9 dans l minute, veuillez v\xE9rifier votre bo\xEEte aux lettres.",
      send_email_success_msg: "Un e-mail de v\xE9rification a \xE9t\xE9 envoy\xE9, veuillez v\xE9rifier votre bo\xEEte aux lettres.",
      wrong_first_name_msg: "Veuillez entrer votre pr\xE9nom correct.",
      change_pp_success_msg: "Votre photo de profil a \xE9t\xE9 chang\xE9e avec succ\xE8s.",
      error: "Quelque chose s'est pass\xE9",
      success: "R\xE9ussi"
    },
    picture: {},
    confirm_crop: "Confirmer",
    email_verification_note: {
      button: "Renvoyer le code de v\xE9rification",
      msg: "Veuillez v\xE9rifier votre e-mail avant d'utiliser la plateforme.",
      title: "V\xE9rifiez votre e-mail"
    },
    confirm_delete_pp: {
      delete_button: "Supprimer le compte",
      cancel_button: "Annuler",
      msg: "Pour confirmer que vous souhaitez supprimer votre compte, veuillez saisir votre pr\xE9nom ci-dessous et cliquer sur le bouton Supprimer le compte.",
      modal_title: "Confirmer la suppression"
    },
    click_to_upload_file_constraints: "ou faites glisser et d\xE9posez PNG, JPEG ou JPG",
    click_to_upload: "Cliquez pour t\xE9l\xE9charger",
    save_changes: "Enregistrer les modifications",
    delete_my_account_title_sub: "Il n'y a pas de retour en arri\xE8re.",
    delete_my_account_title: "Supprimer mon compte",
    profile_picture_title_sub: "T\xE9l\xE9chargez votre photo de profil.",
    profile_picture_title: "Photo de profil",
    personal_info_title_sub: "Mettez \xE0 jour vos informations personnelles.",
    personal_info_title: "Informations personnelles"
  },
  connections: {
    modal: {
      copy_error: "Erreur lors de la copie. Veuillez copier manuellement",
      copied: "La cl\xE9 a \xE9t\xE9 copi\xE9e dans le presse-papiers",
      warning: {
        title: "Valeurs manquantes",
        fill: "Veuillez remplir tous les champs"
      },
      delete: {
        subtitle: "\xCAtes-vous s\xFBr de vouloir supprimer ces cl\xE9s ? Cette action ne peut pas \xEAtre annul\xE9e.",
        title: "Supprimer {{ count }} cl\xE9s"
      },
      delete_cancel: "Annuler",
      delete_confirm: "Supprimer",
      button_copy: "Copier et fermer",
      create: {
        subtitle: "Une fois cr\xE9\xE9e, cette cl\xE9 ne sera plus jamais visible.\nSi elle est compromise, la cl\xE9 doit \xEAtre supprim\xE9e et une autre doit \xEAtre cr\xE9\xE9e.",
        title: "Cr\xE9er une cl\xE9 API"
      },
      name: {
        label: "Nom cl\xE9",
        placeholder: "Exemple de nom"
      },
      expiration: {
        label: "Dur\xE9e de validit\xE9",
        placeholder: "Choisissez une dur\xE9e"
      },
      button_create: "Cr\xE9er une cl\xE9"
    },
    options: {
      year: "{{ number }} An",
      months: "{{ number }} Mois",
      days: "{{ number }} Jours",
      day: "{{ number }} Jour"
    },
    days: {
      error: "Les jours d'expiration sont requis"
    },
    name: {
      error: "Le nom est trop court"
    },
    table_name: {
      app: "Cl\xE9s d'application",
      personnal: "Cl\xE9s personnelles"
    },
    tokens_table: {
      col_name: {
        enterprise: "Entreprise",
        user: "Utilisateur",
        expires_at: "Date d'expiration",
        created_at: "date de cr\xE9ation",
        name: "Nom de la cl\xE9"
      }
    }
  },
  seats: {
    payment_warning: {
      msg: {
        user: "Veuillez contacter ce responsable d'entreprise."
      },
      button: "Contactez le service client",
      title: "Il y avait un probl\xE8me avec votre paiement"
    },
    member_table: {
      col_name: {
        right: "Droits",
        role: "R\xF4le",
        name: "Nom"
      }
    },
    no_slot_warning: {},
    delete_user: {
      multiple: {
        confirm_msg: "\xCAtes-vous s\xFBr de vouloir supprimer ces {{number}} utilisateurs ?"
      },
      success_msg: "L'utilisateur a \xE9t\xE9 supprim\xE9.",
      confirm_msg: "\xCAtes-vous s\xFBr de vouloir supprimer cet utilisateur {{firstName}} {{lastName}} ?"
    },
    am_table: {
      col_name: {
        phone: "Num\xE9ro de t\xE9l\xE9phone du responsable de compte",
        email: "Adresse e-mail du responsable de compte",
        name: "Responsables de compte"
      }
    },
    plan_card: {
      info: "{{utilis\xE9}} de {{total}} si\xE8ges utilis\xE9s"
    },
    institutional: {
      name: "Institutionnel"
    },
    investor: {
      name: "Investisseur"
    },
    consumer: {
      name: "Consommateur"
    }
  },
  security: {
    link_device: {
      notice: "Nous allons dissocier votre appareil actuel de votre compte afin que vous puissiez lier votre compte \xE0 un nouvel appareil. Vous serez d\xE9connect\xE9 afin de lier le nouvel appareil. L'ancien TOTP ne fonctionnera plus. Veuillez confirmer si vous souhaitez proc\xE9der \xE0 l'op\xE9ration.",
      subtitle: "Liez votre compte \xE0 un nouvel appareil si vous n'avez plus acc\xE8s \xE0 l'appareil li\xE9 actuel"
    },
    warning_note: {
      content: "Il est recommand\xE9 de r\xE9g\xE9n\xE9rer vos codes de r\xE9cup\xE9ration p\xE9riodiquement. Proc\xE9dez \xE0 l'op\xE9ration avec le bouton ci-dessous.",
      title: "R\xE9g\xE9n\xE9rez vos codes de r\xE9cup\xE9ration"
    },
    renegerate_code: {
      success: "Nouveaux codes de r\xE9cup\xE9ration enregistr\xE9s avec succ\xE8s.",
      title: "R\xE9g\xE9n\xE9rer les codes de r\xE9cup\xE9ration",
      subtitle: "Vos anciens codes de r\xE9cup\xE9ration ne fonctionneront plus"
    },
    password: {
      disclaimer: "Le mot de passe doit contenir au moins 8 caract\xE8res.",
      success_message: "Mot de passe mis \xE0 jour avec succ\xE8s !",
      error_message: "Les mots de passe doivent \xEAtre identiques",
      save_changes: "Enregistrer les modifications",
      confirm: "Confirmer le nouveau mot de passe",
      subtitle: "Changez votre mot de passe",
      title: "Mot de passe"
    }
  },
  company: {
    set_contact_user: {
      success: "L'utilisateur a \xE9t\xE9 d\xE9fini comme utilisateur de contact avec succ\xE8s."
    }
  },
  organization: {
    details: {
      contact_subtitle: "R\xE9f\xE9rence de communication utilisateur",
      contact_name: "Contactez l'utilisateur",
      phone: "Num\xE9ro de t\xE9l\xE9phone",
      email: "Adresse e-mail",
      zip: "ZIP",
      city: "Ville",
      address: "Adresse",
      country: "Pays",
      name: "Nom de l'entreprise",
      subtitle: "Mettez \xE0 jour les d\xE9tails de votre entreprise.",
      title: "Informations sur l'entreprise"
    },
    notification: {
      update_success: "Mises \xE0 jour appliqu\xE9es avec succ\xE8s."
    },
    logo: {
      subtitle: "Mettre \xE0 jour le logo de votre entreprise.",
      title: "Logo de l'entreprise"
    }
  },
  collaborator: {
    modal: {
      link_companies: {
        success_msg: "Les entreprises g\xE9r\xE9es par {{name}} ont \xE9t\xE9 mises \xE0 jour avec succ\xE8s.",
        subtitle: "Entreprise",
        title: "Entreprises"
      }
    },
    col: {
      invitation_process: "Processus d'invitation"
    },
    reinvite_user: "Un e-mail d'invitation a \xE9t\xE9 envoy\xE9 avec succ\xE8s."
  },
  companies: {
    table_name: "Les membres de l'entreprise"
  }
};
var unsaved_screener = {
  tooltip: {
    save_button: "Enregistrez vos modifications non enregistr\xE9es avec ce bouton de sauvegarde"
  },
  warning: {
    content: "Vous avez un \xE9cran non enregistr\xE9 en cours."
  },
  modal: {
    title: "Vous \xEAtes sur le point de quitter la page de s\xE9lection"
  },
  button: {
    back_to_screener: "Retourner au filtre",
    leave_page: "Proc\xE9der sans enregistrer"
  }
};
var management = {
  nav: {
    preferences: {
      subtitle: "G\xE9rez vos pr\xE9f\xE9rences",
      title: "Pr\xE9f\xE9rences"
    },
    connections: {
      subtitle: "Cr\xE9er et g\xE9rer des cl\xE9s API pour acc\xE9der aux donn\xE9es de quanthome depuis un syst\xE8me d'exploitation, une application ou un autre service.",
      title: "API"
    },
    edit_company: {},
    create_company: {},
    company: {},
    requests: {
      subtitle: "Toutes les demandes seront rassembl\xE9es ici.",
      title: "Demandes"
    },
    contracts: {
      title: "Contrats"
    },
    users: {
      subtitle: "Tous les utilisateurs de toutes les entreprises se rassemblent ici.",
      title: "Utilisateurs"
    },
    teams: {
      subtitle: "Voici tout ce que vous devez savoir sur les personnes acc\xE9dant \xE0 la plateforme dans votre entreprise.",
      title: "\xC9quipes"
    },
    companies: {
      title: "Entreprises",
      subtitle: "Voici toutes les entreprises s'abonnant \xE0 la plateforme."
    },
    profile: {
      subtitle: "Mettez \xE0 jour les param\xE8tres de votre profil.",
      title: "Profil"
    },
    security: {
      subtitle: "G\xE9rez votre mot de passe pour acc\xE9der \xE0 la plateforme.",
      title: "S\xE9curit\xE9"
    },
    languages: {
      subtitle: "G\xE9rer vos langues"
    },
    language: {
      title: "Langue"
    },
    organization: {
      subtitle: "Voici toutes les informations sur l'entreprise dans laquelle vous travaillez.",
      title: "Organisation"
    },
    seats: {
      subtitle: "Voici tout ce que vous devez savoir sur les si\xE8ges et la facturation",
      title: "Si\xE8ges"
    },
    collaborators: {
      subtitle: "Voici tout ce que vous devez savoir sur les personnes acc\xE9dant \xE0 la plateforme dans votre entreprise.",
      title: "Collaborateurs"
    }
  },
  email: {
    addresses: {
      empty: "Vos utilisateurs seront affich\xE9s ici",
      description: "Faites-le un par un ou collez une liste d'emails s\xE9par\xE9s par une virgule et validez avec entrer",
      title: "Adresses e-mail"
    }
  },
  category: {
    communication: {
      title: "Communication"
    },
    administration: {
      title: "Administration"
    },
    customers: {
      title: "Clients"
    },
    personal: {
      title: "Personnel"
    },
    company: {},
    team: {
      title: "\xC9quipe"
    }
  },
  header: {
    gotoplatform: "Allez \xE0 la plateforme",
    logout: "Se d\xE9connecter"
  }
};
var double_range_input = {
  year_error_msg: "Veuillez entrer une ann\xE9e valide",
  end: "date de fin",
  error_msg: "La valeur minimale doit \xEAtre inf\xE9rieure \xE0 la valeur maximale.",
  clear: "clair",
  max: "max",
  min: "min"
};
var overview = {
  property: {
    mixed: "Propri\xE9t\xE9 institutionnelle",
    unlisted_fund: "Fonds non cot\xE9",
    real_estate_firm: "Entreprise immobili\xE8re",
    listed_fund: "Fonds r\xE9pertori\xE9",
    foundation: "Propri\xE9t\xE9 de la fondation"
  }
};
var source_badge = {
  market_data: "Donn\xE9es de march\xE9",
  quanthome_data: "Quanthome data",
  estimated: "Estim\xE9",
  forecasted: "Pr\xE9vu",
  computed: "Calcul\xE9",
  expected: "Attendu",
  listed: "\xC9num\xE9r\xE9",
  reported: "Signal\xE9",
  official: "Officiel"
};
var multiple_select = {
  clear: "clair",
  placeholder: {
    search: "Immobilier"
  }
};
var single_select = {
  clear: "clair"
};
var screener_bar = {
  main_button: {
    screener: "Screener",
    launch: "Lancer"
  }
};
var modal = {
  notifications: {
    subtitle_emailinfo: "g\xE9rez les e-mails que vous recevrez de notre part",
    title_emailinfo: "Vous pouvez maintenant d\xE9finir vos pr\xE9f\xE9rences de messagerie",
    title_recoverycode: "R\xE9g\xE9n\xE9rez vos codes de r\xE9cup\xE9ration maintenant",
    administration_linked: "Vous pouvez maintenant g\xE9rer l'entreprise {{enterprise}}.",
    administration_unlinked: "Entreprise {{enterprise}} a \xE9t\xE9 retir\xE9e de votre liste de gestion.",
    administration_examination: "Entreprise {{enterprise}} est en \xE9tat d'examen depuis un mois.",
    administration_days_left: "La situation de l'entreprise {{enterprise}} n\xE9cessite votre attention : l'\xE9tat {{currentState}} a {{daysLeft}} jours restants.",
    title_administration: "Administration",
    title: "Notifications",
    empty_message: "Pas de notifications"
  },
  downloads: {
    not_available: "La fonctionnalit\xE9 de t\xE9l\xE9chargement n'est pas disponible avec votre plan tarifaire actuel.",
    subtitle: {
      file_about_to_expire: "Votre fichier va bient\xF4t expirer",
      file_failed: "Votre fichier a \xE9chou\xE9 en raison d'une erreur - Veuillez contacter votre administrateur.",
      file_expired: "Votre fichier a expir\xE9",
      file_ready: "Votre fichier est pr\xEAt \xE0 \xEAtre t\xE9l\xE9charg\xE9",
      file_be_ready_soon: "Votre fichier sera bient\xF4t pr\xEAt \xE0 \xEAtre t\xE9l\xE9charg\xE9"
    },
    empty_message: "Pas de t\xE9l\xE9chargements",
    title: "T\xE9l\xE9chargements"
  },
  notification: {
    title_download: "T\xE9l\xE9charger"
  },
  history: {
    empty_history: "No history"
  },
  preset: {
    empty_message: "No presets",
    title: "Pr\xE9r\xE9glages"
  },
  historic: {
    title: "Histoire"
  },
  leaving: {
    registry: {},
    buttons: {}
  },
  support: {
    submit_button: "Demandez \xE0 notre support",
    select: {
      placeholder: "Choisir",
      title: "Probl\xE8me, message, besoin, id\xE9e...",
      need: "Besoin",
      message: "Message",
      problem: "Probl\xE8me"
    },
    message: "Message",
    complete_title: "Support / Contact",
    error_message: {
      no_message: "Vous devez \xE9crire un message",
      too_many_char: "Trop de caract\xE8res {{char_nb}} > 500",
      bad_category: "Vous devez s\xE9lectionner une cat\xE9gorie"
    },
    success: {
      message: "Votre rapport a \xE9t\xE9 envoy\xE9 avec succ\xE8s \xE0 notre service client",
      title: "Rapport envoy\xE9"
    },
    title: "Aide",
    upload: "Uploader"
  }
};
var line_chart = {
  placeholder: {
    no_data_selected: "Veuillez s\xE9lectionner les donn\xE9es \xE0 afficher"
  }
};
var empty_chart = {
  message: "No data"
};
var administration = {
  companies: {
    col_name: {
      developer_mode: "Mode d\xE9veloppeur",
      investor_users: "Utilisateurs investisseurs",
      institutional_users: "Utilisateurs institutionnels",
      consumer_users: "Utilisateurs consommateurs",
      free_institutional_seat: "Si\xE8ges institutionnels restants",
      used_institutional_seat: "Si\xE8ges utilis\xE9s institutionnels",
      total_institutional_seat: "Total des si\xE8ges institutionnels",
      free_investor_seat: "Si\xE8ges d'investisseur restants",
      used_investor_seat: "Si\xE8ges utilis\xE9s par les investisseurs",
      total_investor_seat: "Total des si\xE8ges d'investisseurs",
      free_consumer_seat: "Si\xE8ges consommateurs restants",
      used_consumer_seat: "Si\xE8ges utilis\xE9s par les consommateurs",
      total_consumer_seat: "Nombre total de si\xE8ges pour les consommateurs",
      used_all_seats: "Total des si\xE8ges utilis\xE9s",
      total_all_seats: "Total des si\xE8ges",
      license_use: "Utilisation de la licence",
      all_users: "Membres de l'\xE9quipe",
      contact_user_phone: "Contact num\xE9ro de t\xE9l\xE9phone de l'utilisateur",
      contact_user_email: "Contact utilisateur email",
      contact_user: "Contacter l'utilisateur",
      related_am: "Responsable de compte associ\xE9",
      expiration_date: "Date de fin de contrat",
      subscription_start: "Date d'abonnement",
      days_left: "Jours restants",
      state: "Type de contrat",
      name: "Entreprise"
    },
    headers: {
      companies_count: "{{count}} Entreprises"
    },
    actions: {
      update_company_slots: "Mettre \xE0 jour les cr\xE9neaux de l'entreprise",
      update_company: "Modifier les param\xE8tres de l'entreprise"
    },
    cells: {},
    teams: "\xE9quipes",
    table_title: "Entreprises"
  },
  collaborators: {
    headers: {}
  },
  breadcrumb: {
    details: "D\xE9tails",
    companies: "Entreprises"
  }
};
var treemap = {
  label: {
    label: "\xC9tiquette",
    value: "Valeur",
    percentage: "Pourcentage"
  },
  unit: {
    percentage: "%"
  }
};
var action = {
  label: {
    create_key: "Cr\xE9er une cl\xE9",
    create_user: "Cr\xE9er un utilisateur",
    delete: "Supprimer"
  },
  portfolios: {
    name: {
      change_success: {
        message: "Vous avez r\xE9ussi \xE0 changer le nom du portefeuille",
        title: "Le nom a \xE9t\xE9 chang\xE9"
      }
    },
    remove: {
      loading: {
        message: "Retrait de {{ asset }}(s) de votre portefeuille"
      },
      confirmation: {
        question: "\xCAtes-vous s\xFBr de vouloir retirer l'actif de ce portefeuille ?",
        title: "Retirer du portefeuille"
      }
    }
  },
  users: {
    remove: {
      confirmation: {
        question: "\xCAtes-vous s\xFBr de vouloir supprimer ces utilisateurs ?"
      }
    }
  },
  companies: {}
};
var pyramid_chart = {
  label: {
    age: "\xC2ge",
    unit: "%",
    percentage: "Pourcentage",
    woman: "Femme",
    man: "Homme",
    group: "Groupe"
  }
};
var errors = {
  fii: {
    fii_portfolio_duplicated_dwelling_id: "Toutes les habitations sont d\xE9j\xE0 dans ce portefeuille.",
    fii_invalid_dwelling_id: "Aucun identifiant de logement valide.",
    fii_portfolio_duplicated_building_id: "Tous les b\xE2timents sont d\xE9j\xE0 dans ce portefeuille.",
    fii_invalid_building_id: "Pas d'identifiant de b\xE2timent valide."
  }
};
var buildings = {
  type: {
    commercial: "Commercial",
    residential: "R\xE9sidentiel",
    mixed: "Mixte"
  }
};
var items = {
  charts: {
    gradient: {
      subject: {
        index: "Index",
        indice: "Indice"
      }
    }
  },
  array: {
    columns: {
      title: {
        difference: "Diff\xE9rence",
        potential: "Estim\xE9",
        real: "Signal\xE9 / List\xE9"
      }
    }
  }
};
var gradient = {
  quartile_3: "3\xE8me quartile",
  quartile_2: "M\xE9diane",
  quartile_1: "1er quartile",
  chart: {
    no_data: "No data"
  },
  label: {
    high: "\xC9LEV\xC9",
    low: "FAIBLE"
  }
};
var badge = {};
var valuations = {
  actions: {
    reset: "R\xE9initialiser"
  }
};
var header = {
  navbar: {
    institutions: "Institutions",
    map: "Carte",
    funds: "Institutions",
    portfolios: "Portefeuilles",
    watchlist: "Liste de surveillance",
    screener: "Immobilier",
    dashboard: "Tableau de bord"
  },
  dropdown_menu: {
    my_company: "Ma soci\xE9t\xE9",
    settings: "Param\xE8tres",
    logout: "D\xE9connexion",
    back_office: "Administration",
    profile: "Mon Profil"
  }
};
var input = {
  select: "s\xE9lectionner",
  edit: "\xE9diter"
};
var view = {
  switch: {
    cards: "Voir les cartes",
    table: "Voir le tableau"
  }
};
var unit = {
  chf_per_m2: "CHF/m\xB2"
};
var policy = {
  cookies: {
    types_list: {}
  },
  eea_rights_list: {},
  data_list: {},
  data_usage: {},
  sharing_list: {}
};
var login = {
  error: "Erreur lors de la connexion"
};
var watchlist = {
  buildings: {
    empty: "Pas de liste de surveillance des b\xE2timents"
  },
  dwellings: {
    empty: "No dwellings watchlist"
  }
};
var download = {
  action_label: "T\xE9l\xE9charger CSV",
  request_created: "Demande cr\xE9\xE9e."
};
var profile_train = {
  tooltip: {}
};
var impressum = {
  mail: "E-mail :",
  address: "Adresse postale : Avenue Mon-Repos 24, 1005 Lausanne",
  text: "Impressum Quanthome SA utilise le site web "
};
var notification = {
  terminated_session: {
    message: "Vous pourriez \xEAtre connect\xE9 sur un autre appareil ou la dur\xE9e de vie de la session a \xE9t\xE9 atteinte.",
    title: "Session termin\xE9e"
  }
};
var paywall = {
  button: {
    logout: "D\xE9connexion"
  },
  subtitle: "Veuillez contacter votre responsable de compte.",
  message: {
    error: "Il y a une erreur avec votre abonnement.",
    no_slot: "Vous n'avez actuellement aucun droit d'utiliser l'application.",
    slot_out_of_scope: "Cette fonctionnalit\xE9 n'est pas incluse dans vos emplacements d'autorisation.",
    out_of_scope: "Cette fonctionnalit\xE9 n'est pas incluse dans votre abonnement entreprise.",
    end_trial: "Votre essai est termin\xE9."
  }
};
var dashboard = {
  trend: {},
  rent: {},
  indicators: {
    reference_interest_rate: "taux d'int\xE9r\xEAt de r\xE9f\xE9rence",
    switzerland_vacancy_rate: "Taux de Vacance Suisse",
    ipc_lik: "CPI Indice des prix \xE0 la consommation (IPC/LIK)",
    snb_policy_rate: "Taux d'int\xE9r\xEAt de la Banque nationale suisse"
  },
  indices: {
    construction_price_index_total: "Indice de Construction Suisse - Total",
    construction_price_index_civil_engineering: "Indice de construction suisse - G\xE9nie civil",
    construction_price_index_building: "Indice de Construction Suisse - B\xE2timent",
    swiss_residential_property_price: "Indice des prix des propri\xE9t\xE9s r\xE9sidentielles en Suisse"
  },
  error: {
    feature: {
      rights: "Vous n'avez pas acc\xE8s \xE0 cette fonctionnalit\xE9"
    }
  },
  empty: {
    funds: "Vous n'avez pas de fonds",
    portfolios: "Vous n'avez aucun portefeuille"
  }
};
var widget = {
  side_menu: {}
};
var status = {
  resend: "Renvoyer",
  not_complete: "Non compl\xE9t\xE9",
  completed: "Termin\xE9"
};
var option = {
  no_plan: "Pas de plan",
  sudo: "Sudo",
  consumer: "Consommateur",
  investor: "Investisseur",
  institutional: "Institutionnel",
  am: "Responsable de compte",
  admin: "Admin",
  user: "Utilisateur",
  manager: "Gestionnaire",
  owner: "Propri\xE9taire",
  triage: "Triage"
};
var boarding = {
  suggested_otp: "Application OTP sugg\xE9r\xE9e :"
};
var footer = {
  confidential_policy: "Politique de Confidentialit\xE9",
  impressum: "Impressum"
};
var potfolios = {
  sections: {
    assets: {
      title: "Inventaire"
    },
    social: {
      title: "Social"
    },
    environmental: {
      title: "Environnemental"
    },
    financials: {
      title: "Finances"
    },
    description: {
      title: "Description"
    }
  },
  tiles: {
    charges: {
      title: "Charges"
    },
    evolution: {
      title: "\xC9volution"
    },
    revenue: {
      title: "Revenu"
    }
  },
  widget: {
    charges: {
      title: "Charges"
    },
    revenue: {
      title: "Revenu"
    }
  }
};
var inputs = {
  placeholder: {
    input_name: "Entrer le nom",
    preset_name: "Nom du pr\xE9r\xE9glage",
    choose_option: "Choisissez une option"
  }
};
var entity_selector = {
  search: "Recherche",
  button: {
    save: "Enregistrer les modifications",
    cancel: "Annuler"
  }
};
var users = {
  role: {
    user: "Utilisateur"
  },
  roles: {
    triage: "Triage",
    owner: "Propri\xE9taire"
  }
};
var general = {
  app_wall: {
    redirect: {
      message: "Vous n'avez pas acc\xE8s \xE0 l'application actuellement. Nous vous redirigerons vers les param\xE8tres\u2026"
    }
  }
};
var password = "Nouveau mot de passe";
var traits = {
  contact: {
    office_phone: "Num\xE9ro de t\xE9l\xE9phone professionnel"
  },
  email: "Adresse e-mail",
  name: {
    last: "Nom de famille",
    first: "Pr\xE9nom"
  }
};
var search = {
  title: "R\xE9sultat de recherche",
  nothing_found: "Rien trouv\xE9",
  placeholder: "Adresse, EGID, Nom, Fonds, ISIN..."
};
var fr_default = {
  table_header,
  boarding_page,
  funds,
  units,
  asset,
  table_actions,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  global,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  table,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search
};

export {
  table_header,
  boarding_page,
  funds,
  units,
  asset,
  table_actions,
  preset,
  tile,
  error,
  companies,
  balance_sheet,
  actions,
  common,
  income_statement,
  global,
  asset_page,
  portfolios,
  portfolio,
  candle_stick_chart,
  screener,
  table,
  setting,
  unsaved_screener,
  management,
  double_range_input,
  overview,
  source_badge,
  multiple_select,
  single_select,
  screener_bar,
  modal,
  line_chart,
  empty_chart,
  administration,
  treemap,
  action,
  pyramid_chart,
  errors,
  buildings,
  items,
  gradient,
  badge,
  valuations,
  header,
  input,
  view,
  unit,
  policy,
  login,
  watchlist,
  download,
  profile_train,
  impressum,
  notification,
  paywall,
  dashboard,
  widget,
  status,
  option,
  boarding,
  footer,
  potfolios,
  inputs,
  entity_selector,
  users,
  general,
  password,
  traits,
  search,
  fr_default
};
