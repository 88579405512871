import { ReactNode } from 'react';
import { TableActionProps } from './type';
import { OverviewActionFunction } from '../ObjectList/type';
import { TableActionFunction } from '../Table/types';

export type Overview = TableActionProps & {
  assetId: number;
  isTracked: boolean;
};

interface Return {
  enabled: boolean;
  label: string;
  icon: ReactNode;
}

export type OverviewReturn = Return & {
  function?: OverviewActionFunction;
};

export type TableReturn = Return & {
  function?: TableActionFunction;
};

export function isOverview(props: unknown): props is Overview {
  return Boolean((props as Overview).assetId);
}
