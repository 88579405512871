import WatchilstAdd from '@/assets/icons/Watchlist--add.svg?react';
import WatchlistDelete from '@/assets/icons/Watchlist--delete.svg?react';
import { UseParseError } from '@/features/helpers/fiiErrorParser';
import UseNotifications from '@/features/helpers/useNotifications';
import assetApi from '@/features/pia/sections';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '../Table/types';
import { Overview, OverviewReturn, TableReturn, isOverview } from './trackerType';
import { TableActionProps } from './type';

export default function UseToggleToTracker<
  P extends (Overview | TableActionProps) & { inTable?: boolean },
>(props: P): P extends Overview ? OverviewReturn : TableReturn {
  const { enabled, asset, type = 'asset', inTable = false } = props;
  const [addToTracker, { error: addError, isSuccess: done }] = assetApi.useAddTrackersMutation();
  const [removeFromTracker, { error: removeError, isSuccess: removeDone }] =
    assetApi.useDeleteTrackersMutation();
  const notification = UseNotifications();

  const { message: addMessage } = UseParseError(addError);
  const { message: removeMessage } = UseParseError(removeError);
  const { t } = useTranslation();

  const prevDone = useRef({ done, removeDone });

  useEffect(() => {
    const addSuccessed = prevDone.current.done !== done && done;
    const removeSuccessed = prevDone.current.removeDone !== removeDone && removeDone;
    if (addSuccessed || removeSuccessed) {
      notification.show({
        title: t('common.success'),
        message: t(
          addSuccessed
            ? 'actions.watchlist.add.success.message'
            : 'actions.watchlist.remove.success.message'
        ),
        color: 'green',
        separate: true,
      });
    }
    prevDone.current = { done, removeDone };
  }, [done, notification, removeDone, t]);

  useEffect(() => {
    if ((removeMessage && !removeDone) || (addMessage && !done)) {
      notification.show({
        title: t('common.error'),
        message: addMessage || removeMessage,
        color: 'yellow',
        separate: true,
      });
    }
  }, [addMessage, done, notification, removeDone, removeMessage, t]);

  const action = useMemo<OverviewReturn | TableReturn>(() => {
    const fn = (remove?: boolean) => {
      return ({ rows, subrows }: ActionProps) => {
        const items = subrows ? subrows : rows;
        const groups = subrows ? rows : [];
        if (enabled || enabled === undefined) {
          if (remove) {
            removeFromTracker({ [`${asset}s`]: items });
          } else {
            addToTracker({
              [`${asset}s`]: items,
              buildingGroups: groups,
              type,
            });
          }
        }
      };
    };

    const action = {
      enabled: enabled === undefined ? true : enabled,
      label: t(`${inTable ? 'table_actions' : 'actions'}.watchlist.add.button.label`),
      icon: <WatchilstAdd />,
    };

    if (isOverview(props))
      return {
        enabled: enabled === undefined ? true : enabled,
        label: props.isTracked
          ? t(`${inTable ? 'table_actions' : 'actions'}.watchlist.remove.button.label`)
          : action.label,
        icon: props.isTracked ? <WatchlistDelete /> : action.icon,
        function: (_asset: unknown) => {
          fn(props.isTracked)({ rows: [], subrows: [props.assetId] });
        },
      };
    else
      return {
        ...action,
        function: fn(),
      };
  }, [addToTracker, asset, enabled, inTable, props, removeFromTracker, t, type]);

  return action;
}
