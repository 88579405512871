import { Tooltip } from '@mantine/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@/scss/components/EllipsisText.css';
import notEmpty from '@/features/helpers/notEmpty';

export default function EllipsisText({
  label,
  element,
  noTranslation,
  separator,
  classname,
}: {
  label?: string | string[];
  element?: React.JSX.Element;
  classname?: string;
  noTranslation?: boolean;
  separator?: string;
}) {
  const [openedLabel, setOpenedLabel] = useState(false);
  const refLabel = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const labels = Array.isArray(label) ? label : [label];
  const translatedLabels = labels
    .filter(notEmpty)
    .map((label) => (noTranslation ? label : t(label)))
    .join(separator || ', ');
  const toRender = element ? element : translatedLabels;

  if (!label) return null;

  return (
    <Tooltip withinPortal label={translatedLabels} opened={openedLabel} zIndex={402}>
      <span
        ref={refLabel}
        onMouseEnter={() => {
          if (
            refLabel.current &&
            (refLabel.current?.offsetWidth || 0) < (refLabel.current?.scrollWidth || 0)
          ) {
            setOpenedLabel(true);
          }
        }}
        onMouseLeave={() => setOpenedLabel(false)}
        className={'label-component' + (classname ? ` ${classname}` : '')}
      >
        {toRender}
      </span>
    </Tooltip>
  );
}
